<script lang="ts" setup>
import { computed, defineProps, withDefaults } from "vue";

const props = withDefaults(
  defineProps<{
    language: string;
    light?: boolean;
  }>(),
  {
    light: false,
  },
);

const classes = computed(() => {
  return props.light ? "bg-white" : "bg-grey-lighten-4";
});
</script>

<template>
  <span
    :class="classes"
    class="text-uppercase rounded text-grey-darken-1 pa-1 caption d-flex align-center"
    data-qa="language-tag"
  >
    {{ props.language }}
  </span>
</template>

<style lang="scss" scoped></style>
