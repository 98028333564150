<script>
import DatePicker from "@/components/pickers/DatePicker.vue";
import TimePicker from "@/components/pickers/TimePicker.vue";
import { VInput } from "vuetify/components";

export default {
  name: "DateTimePicker",
  components: { TimePicker, DatePicker },
  extends: VInput,
  props: {
    modelValue: String,
    dateLabel: String,
    timeLabel: String,
    min: String,
  },
  emits: ["update:modelValue"],
  data() {
    return {
      date: null,
      time: null,
    };
  },
  computed: {
    fullDate() {
      return this.modelValue ? this.$dayjs(this.modelValue).format("MM/DD/YYYY HH:mm") : null;
    },
  },
  watch: {
    modelValue(value) {
      this.setDateTime(value);
    },
    date(value) {
      this.emitValue(value, this.time);
    },
    time(value) {
      this.emitValue(this.date, value);
    },
  },
  created() {
    this.setDateTime(this.modelValue);
  },
  methods: {
    setDateTime(value) {
      if (value) {
        const date = this.$dayjs(value);
        this.date = date.format("YYYY-MM-DD");
        this.time = date.format("HH:mm");
      }
    },
    emitValue(date, time) {
      if (!date || !time) {
        return this.$emit("update:modelValue", null);
      }
      const result = [date, time].join("T");
      this.$emit("update:modelValue", result);
    },
    minDateValidator(value) {
      if (!this.min || !value) return true;
      return (
        this.$dayjs(value).isAfter(this.$dayjs(this.min)) ||
        "Date can not be before " + this.$dayjs(this.min).format("MM/DD/YYYY HH:mm")
      );
    },
  },
};
</script>

<template>
  <v-row>
    <v-col>
      <date-picker v-model="date" :label="dateLabel || 'Date'" :min="min" skip-validation></date-picker>
    </v-col>
    <v-col>
      <time-picker v-model="time" :disabled="!date" :label="timeLabel || 'Time'"></time-picker>
    </v-col>
  </v-row>
  <v-row>
    <v-col class="pt-0">
      <v-text-field :model-value="fullDate" :rules="[minDateValidator]" type="hidden" variant="plain"></v-text-field>
    </v-col>
  </v-row>
</template>

<style lang="scss" scoped></style>
