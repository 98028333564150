<script>
import TrucksMixin from "@/views/trucks/TrucksMixin.vue";
import { DateTimeMixin } from "@/mixins/date-time.mixin";
import { mapState } from "pinia";
import { useAppStore } from "@/store/app.store";

export default {
  name: "TruckStatus",
  mixins: [TrucksMixin, DateTimeMixin],
  inheritAttrs: false,
  props: {
    truck: { type: Object, required: true },
    light: { type: Boolean, default: false },
  },
  emits: ["click"],
  computed: {
    ...mapState(useAppStore, ["dispatcher"]),
    classes() {
      if (this.truck.archived) {
        return "bg-grey-darken-2 text-white";
      }
      switch (this.truck.status) {
        case this.truckStatusAvailable:
          return !this.light ? "bg-green-lighten-2 text-white" : "bg-white text-green-lighten-2";
        case this.truckStatusInRoute:
          return "bg-amber-lighten-2 text-grey-darken-3";
        case this.truckStatusWillBeAvailable:
          return !this.light ? "bg-indigo-lighten-2 text-white" : "bg-white text-indigo-lighten-2";
        case this.truckStatusNotAvailable:
          return "bg-grey-lighten-3 text-grey-darken-3";
        case this.truckStatusOnHold:
          return !this.light ? "bg-red-lighten-2 text-white" : "bg-white text-red-lighten-2";
        default:
          return "bg-grey-lighten-4 text-grey-darken-3";
      }
    },
  },
  methods: {
    click() {
      this.$emit("click");
    },
  },
};
</script>

<script setup></script>

<template>
  <v-menu :disabled="!truck?.statusChangedAt || !truck?.statusChangedBy" location="top start" offset="4" open-on-hover>
    <template #activator="{ props }">
      <div
        :class="classes"
        class="status-label rounded-lg pr-2 pl-2 cursor-pointer"
        v-bind="{ ...$attrs, ...props }"
        @click="click"
      >
        {{ $t("trucks.status-" + truck.status) }}
      </div>
    </template>
    <v-card>
      <v-card-text>
        <div>
          Change time: <span class="font-weight-500">{{ dateTZ(truck.statusChangedAt, dispatcher.timezone) }}</span>
        </div>
        <div>
          Changed by: <span class="font-weight-500 text-primary">{{ truck.statusChangedBy }}</span>
        </div>
      </v-card-text>
    </v-card>
  </v-menu>
</template>
<style scoped>
.status-label {
  white-space: nowrap;
  display: inline-block;
  line-height: 16px !important;
  font-size: 11px;
}
</style>
