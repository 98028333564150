<script lang="ts" setup>
import { computed, defineProps } from "vue";

const props = withDefaults(
  defineProps<{
    bgColor?: string;
    borderColor?: string;
    color?: string;
    icon?: string;
    iconColor?: string;
  }>(),
  {
    bgColor: "amber-lighten-5",
    borderColor: "warning",
    color: "grey-darken-3",
    icon: "mdi-alert-circle-outline",
    iconColor: "warning",
  },
);

const alertClass = computed(() => [`bg-${props.bgColor}`, `border-${props.borderColor}`, `text-${props.color}`]);
</script>

<template>
  <div :class="alertClass" class="rounded pa-2 d-flex align-center font-size-12 font-weight-500">
    <v-icon :color="iconColor" :icon="props.icon" size="20"></v-icon>
    <div class="ml-1">
      <slot></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
