<script>
import BaseView from "@/views/BaseView.vue";
import { mapState } from "pinia";
import { useAppStore } from "@/store/app.store";

export default {
  name: "SidebarComponent",
  mixins: [BaseView],
  props: {
    isCollapsed: Boolean,
    openThreadsCount: Number,
  },
  computed: {
    ...mapState(useAppStore, ["dispatcher"]),
    menu() {
      const items = [],
        host = window.location.hostname,
        isDev = host.indexOf("dev") > -1 || host.indexOf("local") > -1;

      if (this.hasAccess("chats")) {
        items.push({
          icon: "mdi-message-outline",
          title: this.$t("chats.chats"),
          to: { name: "chats" },
          count: this.openThreadsCount,
        });
      }

      // @TODO: Dev only
      if (isDev && this.hasAccess("orders")) {
        items.push({
          icon: "mdi-package-variant-closed",
          title: this.$t("orders.orders"),
          items: [
            {
              title: "Manage orders",
              to: { name: "orders" },
            },
          ],
        });
      }

      // Company: facilities,
      const companySubMenu = [];
      if (this.hasAccess("facilities")) {
        companySubMenu.push({
          title: this.$t("company.carriers"),
          to: { name: "carriers" },
        });
      }

      if (this.hasAccess("customers")) {
        companySubMenu.push({
          title: this.$t("company.customers"),
          to: { name: "customers" },
        });
      }

      if (this.hasAccess("facilities")) {
        companySubMenu.push({
          title: this.$t("company.facilities"),
          to: { name: "facilities" },
        });
      }

      if (companySubMenu.length > 0) {
        items.push({
          icon: "mdi-domain",
          items: companySubMenu,
          title: this.$t("company.companies"),
          url: "/company",
        });
      }

      // Users: coordinators, drivers, owners
      const usersSubMenu = [];
      if (this.hasAccess("drivers")) {
        usersSubMenu.push({
          title: this.$t("drivers.drivers"),
          to: { name: "drivers" },
        });
      }
      if (this.hasAccess("owners")) {
        usersSubMenu.push({
          title: this.$t("drivers.owners"),
          to: { name: "owners" },
        });
      }
      if (this.hasAccess("coordinators")) {
        usersSubMenu.push({
          title: this.$t("drivers.coordinators"),
          to: { name: "coordinators" },
        });
      }

      if (usersSubMenu.length > 0) {
        items.push({
          icon: "mdi-account-multiple-outline",
          items: usersSubMenu,
          title: this.$t("users.users"),
          url: "/users",
        });
      }

      // Fleets: trucks, trailers
      const fleetSubMenu = [];
      if (this.hasAccess("trucks")) {
        fleetSubMenu.push({
          title: this.$t("trucks.trucks"),
          to: { name: "trucks" },
        });
        fleetSubMenu.push({
          title: this.$t("trucks.search"),
          to: { name: "trucks-search" },
        });
        fleetSubMenu.push({
          title: this.$t("trucks.history"),
          to: { name: "trucks-history" },
        });
      }

      if (fleetSubMenu.length > 0) {
        items.push({
          icon: "mdi-truck-outline",
          items: fleetSubMenu,
          title: "Fleet",
          url: "/fleets",
        });
      }

      if (this.hasAccess("dispatchers")) {
        items.push(
          {
            icon: "mdi-clipboard-text-outline",
            title: this.$t("dispatchers.logs"),
            to: { name: "settings-logs" },
          },
          {
            icon: "mdi-account-circle-outline",
            to: { name: "settings-dispatchers" },
            title: this.$t("dispatchers.dispatchers"),
          },
        );
      }

      return items;
    },
  },
  methods: {
    hasActiveChild(item) {
      return item.items.some((child) => child.to.name === this.$route.name);
    },
  },
};
</script>

<template>
  <v-navigation-drawer id="main-sidebar" :permanent="true" app class="pt-3 pb-8 dark-background" width="250">
    <div class="sidebar-navigation d-flex flex-column justify-sm-space-between height-100">
      <div>
        <!-- Sidebar header -->
        <div
          :class="{ 'justify-center': isCollapsed }"
          class="sidebar-nav__header pt-3 pb-3 mb-9 d-flex px-4 align-center justify-center"
        >
          <img
            v-if="dispatcher?.company?.code"
            :src="getAssetUrl('logo/' + dispatcher.company.code + '.svg')"
            alt="Logo"
            class="company-logo"
          />
          <span v-if="!isCollapsed" class="company-name ml-2">{{ dispatcher?.company.name }}</span>
        </div>
        <div
          :class="{ 'pl-4': !isCollapsed, 'text-center': isCollapsed }"
          class="text-uppercase caption font-weight-600 text-nav"
        >
          Menu
        </div>

        <!-- Sidebar navigation -->
        <v-list density="compact">
          <template v-for="(item, i) in menu">
            <!-- Collapsed sidebar navigation -->
            <v-menu
              v-if="item.items && isCollapsed"
              :key="'menu-item-' + i"
              content-class="sidebar-submenu"
              location="right"
              tile
              transition="slide-x-transition"
            >
              <template #activator="{ props }">
                <v-list-item :class="{ 'v-list-item--active': hasActiveChild(item) }" v-bind="props">
                  <template v-if="item.icon" #prepend>
                    <v-icon :color="item.color" size="20">
                      {{ item.icon }}
                    </v-icon>
                  </template>
                  <v-list-item-title class="pa-0">
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item>
              </template>

              <v-list>
                <v-list-item v-for="(child, j) in item.items" :key="j" :link="true" :to="child.to">
                  <v-list-item-title class="pa-0">
                    {{ child.title }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <!-- Full sidebar navigation -->
            <v-list-group v-else-if="item.items && !isCollapsed" :key="i" v-model="item.active" :group="item.url">
              <template #activator="{ props }">
                <v-list-item v-bind="props">
                  <template v-if="item.icon" #prepend>
                    <v-icon :color="item.color" size="20">
                      {{ item.icon }}
                    </v-icon>
                  </template>
                  <v-list-item-title class="pa-0">
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item>
              </template>

              <v-list-item v-for="(child, m) in item.items" :key="m" :link="true" :to="child.to">
                <v-list-item-title class="pa-0">
                  {{ child.title }}
                </v-list-item-title>
              </v-list-item>
            </v-list-group>
            <v-list-item v-else :key="item.title" :link="true" :to="item.to">
              <template v-if="item.icon" #prepend>
                <v-icon :color="item.color" size="20">
                  {{ item.icon }}
                </v-icon>
              </template>
              <v-list-item-title>
                {{ item.title }}
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-list>
      </div>
      <div v-if="!isCollapsed" class="pl-5 pr-5 text-center text-body-2 mt-4 text-nav">&copy; Omni Dispatch, 2023</div>
    </div>
  </v-navigation-drawer>
</template>

<style lang="scss" scoped>
@import "@/assets/style/color";

.sidebar-navigation {
  .company-logo {
    width: 26px;
    height: 26px;
  }

  .company-name {
    font-size: 16px;
    font-weight: 500;
    color: $white;
  }

  .caption {
    font-size: 11px;
  }

  .v-list-item {
    .v-list-item-title {
      color: $nav;
    }

    .v-icon {
      color: $nav;
    }

    :deep(.v-list-item__append) {
      .v-icon {
        color: $nav;
      }
    }

    :deep(.v-list-item__prepend) {
      display: block;
      text-align: center;
    }

    &--active {
      background-color: $dark-nav;

      &:before {
        content: "";
        border-left: 3px solid $primary;
        position: absolute;
        height: 100%;
      }

      .v-list-item-title {
        color: $white;
      }

      .v-icon {
        color: $white;
      }

      :deep(.v-list-item__append) {
        .v-icon {
          color: $white;
        }
      }
    }
  }

  .v-list-group--open {
    .v-list-group__items {
      .v-list-item--active {
        &:before {
          border-left: none;
        }
      }
    }
  }

  .v-list-group__items {
    .v-list-item {
      padding-inline-start: 52px !important;
    }
  }
}
</style>
