<script>
import ChatMixin from "@/views/chats/ChatMixin.vue";
import OmniDialog from "@/components/OmniDialog.vue";
import { mapState } from "pinia";
import { useAppStore } from "@/store/app.store";

export default {
  name: "ClaimChatAction",
  components: { OmniDialog },
  mixins: [ChatMixin],
  props: {
    thread: Object,
  },
  data() {
    return {
      dialogVisible: false,
    };
  },
  computed: {
    ...mapState(useAppStore, ["dispatcher"]),
  },
  methods: {
    async askClaimThread() {
      if (this.thread.dispatcherId && this.thread.dispatcherId !== this.dispatcher.id && !this.thread.resolved) {
        this.dialogVisible = true;
      } else {
        await this.updateThread({ dispatcherId: this.dispatcher.id, resolved: false });
      }
    },
    async reClaimThread() {
      await this.updateThread({ dispatcherId: this.dispatcher.id, resolved: false });
      this.dialogVisible = false;
    },
  },
};
</script>

<template>
  <v-btn
    v-if="thread.dispatcherId !== dispatcher.id || thread.resolved"
    class="mr-2"
    color="primary"
    size="small"
    variant="outlined"
    @click="askClaimThread"
  >
    <v-icon class="mr-1"> mdi-account-plus-outline</v-icon>
    <span class="font-weight-600 text-uppercase text-primary">
      {{ $t("dispatchers.claim") }}
    </span>
  </v-btn>

  <omni-dialog
    v-model="dialogVisible"
    primary-action-label="Claim"
    size="small"
    title="Be attention!"
    @primary-action:click="reClaimThread"
  >
    <div class="text-body-2 text-left text-grey text-grey-darken-1">
      If you want to claim this chat from
      <span v-if="thread.dispatcher">{{ thread.dispatcher.name }}</span>
      please press “Claim”!
    </div>
  </omni-dialog>
</template>

<style lang="scss" scoped></style>
