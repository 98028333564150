<script>
import OrdersMixin from "@/views/orders/OrdersMixin.vue";
import RemoveOrderDialog from "@/views/trips/edit/dialogs/RemoveOrderDialog.vue";
import OmniDialog from "@/components/OmniDialog.vue";
import ShowMore from "@/components/ShowMore.vue";
import OrderStatus from "@/components/status/OrderStatus.vue";
import { OrderCancellationStatusMap } from "@/data/order";

export default {
  name: "OrderBlock",
  components: { OrderStatus, ShowMore, OmniDialog, RemoveOrderDialog },
  mixins: [OrdersMixin],
  props: {
    canEdit: Boolean,
    order: {
      type: Object,
      required: true,
    },
  },
  emits: ["remove"],
  data() {
    return {
      removeOrderDialog: false,
    };
  },
  computed: {
    isCancelled() {
      return OrderCancellationStatusMap.some((o) => o.id === this.order.status);
    },
  },
  methods: {
    removeOrder() {
      this.removeOrderDialog = false;
      this.$emit("remove", this.order);
    },
  },
};
</script>

<template>
  <div class="trip-block" data-qa="order-block">
    <div class="trip-block__inner bg-white pa-4 rounded-lg">
      <div class="trip-block__header d-flex align-center justify-space-between mb-3">
        <div class="d-flex align-center">
          <router-link
            :to="{ name: 'order-view', params: { id: order.id } }"
            class="text-primary font-weight-medium text-decoration-none"
            data-qa="order-number"
            target="_blank"
          >
            Order #{{ order.number }}
          </router-link>
          <order-status v-if="isCancelled" :order="order" class="ml-2"></order-status>
        </div>
        <v-menu v-if="canEdit" offset-y>
          <template #activator="{ props }">
            <v-btn elevation="0" icon="mdi-dots-horizontal" size="x-small" v-bind="props" />
          </template>
          <v-list dense>
            <v-list-item @click="removeOrderDialog = true">
              <v-list-item-title>Remove order from trip</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <div class="d-flex align-center text-grey-darken-1 mb-4">
        REF:
        <show-more :items="order.refNumbers" class="ml-1" data-qa="order-ref-number"></show-more>
      </div>
      <div class="text-grey-darken-3 mb-4 subheading font-weight-medium" data-qa="order-address-route">
        {{ getOrderRoute(order) }}
      </div>
      <div class="d-flex align-center justify-space-between">
        <router-link
          :to="{ name: 'customer-edit', params: { id: order.customer?.id } }"
          class="text-primary font-weight-medium text-decoration-none"
          data-qa="order-customer"
          target="_blank"
        >
          {{ order.customer?.name }}
        </router-link>
        <div class="d-flex">
          <span class="text-grey-darken-2">Rate:</span>
          <span class="text-grey-darken-3 font-weight-medium ml-1" data-qa="order-rate">{{ order.rate }}$</span>
        </div>
      </div>
    </div>
    <omni-dialog
      v-model="removeOrderDialog"
      :title="'Remove order #' + order.number"
      primary-action-label="Remove"
      width="360"
      @primary-action:click="removeOrder"
    >
      <remove-order-dialog :order="order" />
    </omni-dialog>
  </div>
</template>

<style scoped>
.trip-block__inner {
  min-height: 180px;
}
</style>
