<script>
import messaging from "@/firebase";
import * as Sentry from "@sentry/vue";
import { DateTimeMixin } from "@/mixins/date-time.mixin";

import BaseView from "@/views/BaseView.vue";
import SidebarComponent from "@/views/layout/Sidebar.vue";
import ChatMixin from "@/views/chats/ChatMixin.vue";
import ChatDialog from "@/views/chats/ChatDialog.vue";
import GalleryView from "@/components/gallery/GalleryView.vue";
import OmniSnackbar from "@/components/Snackbar.vue";
import { mapActions, mapState } from "pinia";
import { useChatStore } from "@/store/chat.store";
import { useAppStore } from "@/store/app.store";
import { useGalleryStore } from "@/store/gallery.store";
import { defineComponent } from "vue";

const localeField = "lang";

export default defineComponent({
  name: "CabinetLayout",
  components: { OmniSnackbar, GalleryView, ChatDialog, SidebarComponent },
  mixins: [BaseView, ChatMixin, DateTimeMixin],
  data() {
    return {
      langs: [{ id: "en", name: "English" }],
      messages: [],
      showChatDialog: false,
      showNotificationsSetup: false,

      userSidebarCollapsed: false,
      sidebarSmall: false,
      timezoneTime: "",
      timezoneName: "",
    };
  },
  computed: {
    ...mapState(useChatStore, ["chatUser", "offline"]),
    ...mapState(useGalleryStore, ["media", "mediaId"]),
    ...mapState(useAppStore, ["dispatcher", "dispatchers", "title"]),
    viewHasAccess() {
      if (!this.$route.meta.section) return true;
      return this.hasAccess(this.$route.meta.section, this.$route.meta.action || "view");
    },
    openThreadsCount() {
      let count = 0;
      const chatStore = useChatStore();
      for (let thread of chatStore.threads) {
        if (thread.status === "o") {
          count++;
        }
      }
      return count;
    },
    language() {
      for (let lang of this.langs) {
        if (this.$i18n.locale === lang.id) {
          return lang;
        }
      }
      return {};
    },
  },
  watch: {
    chatUser() {
      this.showChatDialog = this.chatUser.id !== undefined;
    },
    showChatDialog(value) {
      if (!value) {
        const chatStore = useChatStore();
        // clear chat user on closing chat dialog
        chatStore.setChatUser({});
      }
    },
  },
  async created() {
    const appStore = useAppStore();
    appStore.setMobile(this.$vuetify.display.mobile);

    let locale = localStorage.getItem(localeField) || "en";
    this.setLocale(locale);
    this.userSidebarCollapsed = this.$storage.getSidebarCollapsed();

    const authorized = await this.loadDispatcher(); // check auth here
    if (authorized) {
      this.connectChat().then();
      this.loadData().then();
    }
    this.calculateTime();
    this.timer = setInterval(this.calculateTime, 5000);
  },
  unmounted() {
    this.disconnectChat();
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    window.addEventListener("DOMContentLoaded", this.handleLoad);
  },
  methods: {
    ...mapActions(useGalleryStore, ["setMediaId"]),
    calculateTime() {
      const now = this.$dayjs();
      this.timezoneTime = now.tz(this.dispatcher.timezone).format("HH:mm");
    },
    async loadDispatcher() {
      let dispatcher = null;
      try {
        const resp = await this.$api.dispatchers.getCurrentDispatcher();
        dispatcher = resp.data;
      } catch (error) {
        // no code
      }

      if (dispatcher) {
        const appStore = useAppStore();
        appStore.setDispatcher(dispatcher);
        Sentry.setUser({
          email: dispatcher.email,
          id: dispatcher.id,
          companyId: dispatcher.companyId,
          companyName: dispatcher.companyName,
          roles: dispatcher.roles,
        });
        const now = this.$dayjs();
        this.timezoneName = this.getShortTZForDate(now.toDate(), this.dispatcher.timezone);
        return true;
      } else {
        this.$api.client.saveToken(null);
        this.$router.replace({ name: "login" });
        return false;
      }
    },
    async loadData() {
      await this.loadDispatchers();
    },
    logout() {
      useChatStore().clearChat();
      this.$api.client.saveToken(null);
      this.$router.replace({ name: "login" });
    },
    setLocale(locale) {
      this.$i18n.locale = locale;
      this.$root.$i18n.locale = locale;
      this.$dayjs.locale(locale);
      //this.$forceUpdate();
    },
    setupNotifications() {
      const that = this;
      messaging
        .requestPermission()
        .then(() => {
          return messaging.getToken();
        })
        .then((token) => {
          that.$api.patch("account/push-token", { push_token: token });
          that.showNotificationsSetup = false;
        });
    },
    handleSidebarSize() {
      this.sidebarSmall = !this.sidebarSmall;
      this.userSidebarCollapsed = this.sidebarSmall;
      this.$storage.setSidebarCollapsed(this.userSidebarCollapsed);
    },
    handleResize() {
      if (this.userSidebarCollapsed !== null) {
        this.sidebarSmall = this.userSidebarCollapsed;
        return;
      }

      this.sidebarSmall = window.innerWidth < 991;
    },
    handleLoad() {
      if (this.userSidebarCollapsed !== null) {
        this.sidebarSmall = this.userSidebarCollapsed;
        return;
      }

      this.sidebarSmall = window.innerWidth < 991;
    },
  },
});
</script>

<template>
  <v-layout :class="{ vertical_collapsed: sidebarSmall }" class="site-page">
    <gallery-view :active-id="mediaId" :items="media" :show="mediaId !== null" @close="setMediaId(null)" />
    <sidebar-component :is-collapsed="userSidebarCollapsed" :open-threads-count="openThreadsCount" />
    <v-app-bar app color="white">
      <div class="d-flex align-center justify-space-between flex-wrap width-100 pr-3 pl-3">
        <div class="d-flex align-center">
          <v-app-bar-nav-icon class="text-grey-darken-1" @click="handleSidebarSize" />
          <!--<div class="pa-0 ml-2 text-grey-darken-1 subheading font-weight-medium text-uppercase">
            {{ title }}
          </div> -->
        </div>
        <div class="d-flex align-center">
          <div class="mr-2">
            <span> {{ timezoneName }} </span>:
            <span
              ><b> {{ timezoneTime }} </b></span
            >
          </div>
          <v-btn color="grey-darken-1" icon="mdi-bell-outline"></v-btn>
          <v-menu location="bottom right" offset="10" origin="top right" transition="scale-transition">
            <template #activator="{ props }">
              <v-btn class="ml-4" v-bind="props" variant="flat">
                <v-avatar size="35">
                  <img v-if="dispatcher?.avatar" :alt="dispatcher.name" :src="dispatcher.avatar" />
                  <v-icon v-else width="35">mdi-account-circle</v-icon>
                </v-avatar>
                <span class="text-grey-darken-2 body-2">
                  {{ dispatcher?.name }}
                </span>
                <v-icon class="text-grey-darken-2 ml-2" small> mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list density="compact">
              <v-list-item>
                <v-list-item-title> Timezone: {{ dispatcher.timezone }}</v-list-item-title>
              </v-list-item>
              <v-divider class="my-1" />
              <v-list-item :to="{ name: 'account-profile' }">
                <v-list-item-title>
                  {{ $t("account.profile") }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item :to="{ name: 'company-profile' }">
                <v-list-item-title>
                  {{ $t("company.profile") }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item :to="{ name: 'change-password' }">
                <v-list-item-title>
                  {{ $t("auth.change-password") }}
                </v-list-item-title>
              </v-list-item>
              <v-divider class="my-1" />
              <v-list-item @click="logout">
                <v-list-item-title>
                  {{ $t("account.logout") }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
    </v-app-bar>
    <v-main>
      <router-view v-if="viewHasAccess" :key="$route.path" />
      <v-container v-else class="pt-10">
        <v-alert type="warning">You don't have access to this section</v-alert>
      </v-container>
    </v-main>
    <v-dialog
      v-model="showChatDialog"
      :no-click-animation="true"
      :persistent="true"
      :scrollable="true"
      max-width="1080"
      transition="dialog-top-transition"
    >
      <chat-dialog :user="chatUser" @close="showChatDialog = false" />
    </v-dialog>
    <omni-snackbar></omni-snackbar>
    <v-snackbar v-model="offline" :timeout="-1" color="#FF5252" location="bottom right" multi-line variant="flat">
      Lost connection to server
    </v-snackbar>
    <v-dialog v-model="showNotificationsSetup" :fullscreen="$vuetify.display.mobile" width="600">
      <v-btn class="ma-4" color="success" @click="setupNotifications"> Подключить уведомления</v-btn>
    </v-dialog>
  </v-layout>
</template>

<style lang="scss">
.v-main__wrap {
  z-index: 2;
}

.mess-counter {
  position: absolute;
  height: 16px;
  top: 0;
  right: 0;
  padding: 0 4px;
  border-radius: 10px;
}

.width-auto {
  width: auto !important;
}

.header {
  color: #000;
  padding-top: 7px;
}

header {
  box-shadow: 0 8px 20px -7px rgba(0, 0, 0, 0.1) !important;
}

.v-data-table table {
  border: solid 1px #ddd;
}

.v-data-table thead tr {
  background: #e1e1e1;
}

.v-data-table th {
  font-size: 0.85rem;
}

.vue-daterange-picker {
  width: 100% !important;
}

.vue-daterange-picker .reportrange-text {
  border: 0 !important;
  padding: 0 !important;
}

.vue-daterange-picker .reportrange-text input {
  text-align: center;
}

.height-100 {
  height: 100%;
}

.width-100 {
  width: 100%;
}

.link {
  cursor: pointer;
}

.v-app-bar-title {
  margin-left: 150px;
}

fieldset {
  border-radius: 5px;
  border: solid 1px #ccc;
}

.pointer {
  cursor: pointer;
}
</style>
