<script>
import NotFound from "@/views/misc/NotFound.vue";
import OmniTabs from "@/components/controls/OmniTabs.vue";
import SidebarLayout from "@/components/layout/SidebarLayout.vue";
import SidebarToggle from "@/components/layout/SidebarToggle.vue";
import NotFoundMixin from "@/mixins/not-found.mixin";
import RouteInfo from "@/views/trips/view/components/RouteInfo.vue";
import RouteStops from "@/views/trips/view/components/RouteStops.vue";
import TripStatus from "@/views/trips/components/TripStatus.vue";
import { mapActions } from "pinia";
import { useSnackbarStore } from "@/store/snackbar.store";

export default {
  name: "TripView",
  components: {
    TripStatus,
    RouteStops,
    RouteInfo,
    SidebarToggle,
    SidebarLayout,
    OmniTabs,
    NotFound,
  },
  mixins: [NotFoundMixin],
  data() {
    return {
      trip: {},
      tab: 0,
    };
  },
  computed: {
    editLink() {
      return {
        name: this.$route.name.replace("view", "edit"),
        params: { id: this.trip.id },
      };
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showSnackbarError"]),
    async load() {
      const tripId = this.$route.params.id;
      const response = await this.$api.trips.getTripById(tripId);
      if (!this.validateEntityResponse(response)) return;
      this.trip = response.data;
      await this.loadAdditionalData();
    },
    isSidebarOpenDefault() {
      let windowWidth = window.innerWidth;
      return windowWidth > 1250;
    },
    async loadAdditionalData() {
      for (const route of this.trip.routes) {
        const truckResponse = await this.$api.trucks.findTruckById(route.truckId);
        if (truckResponse.success) {
          route.$truck = truckResponse.data;
        }
        for (const travelOrder of route.travelOrders) {
          const orderResponse = await this.$api.orders.findOrderById(travelOrder.orderId);
          if (orderResponse.success) {
            travelOrder.$order = orderResponse.data;
          }
        }
      }
    },
  },
};
</script>

<template>
  <v-container v-if="!entityNotFound" :fluid="true" class="pt-6 pb-10 pl-6 pr-6 height-100 users-page">
    <sidebar-layout :sidebar-opened="isSidebarOpenDefault()">
      <div class="w-100">
        <div class="d-flex justify-space-between align-center flex-wrap mb-4">
          <div class="d-flex align-center text-grey-darken-4 title font-weight-medium font-size-24">
            Trip #{{ trip.id }}
            <trip-status :trip="trip" class="ml-4" data-qa="trip-status"></trip-status>
          </div>
          <div v-if="trip.id">
            <v-btn :to="editLink" class="text-uppercase font-weight-600 text-white mr-2" color="primary" variant="flat">
              <v-icon class="mr-2" size="20"> mdi-pencil-outline</v-icon>
              Manage trip
            </v-btn>
            <sidebar-toggle class="mr-2"></sidebar-toggle>
          </div>
        </div>

        <v-card class="bg-transparent" variant="flat">
          <omni-tabs v-model="tab" class="rounded">
            <v-tab key="overview" class="text-uppercase">Overview</v-tab>
            <v-tab class="text-uppercase" value="updates">Order updates</v-tab>
          </omni-tabs>

          <v-window v-model="tab" :touch="false" class="mt-4">
            <v-window-item value="overview">
              <v-row>
                <v-col v-for="route in trip.routes" :key="'info-' + route.id" lg="6" xl="4">
                  <route-info :route="route"></route-info>
                  <route-stops :route="route" :trip="trip" @change="load"></route-stops>
                </v-col>
              </v-row>
            </v-window-item>

            <v-window-item value="updates"></v-window-item>
          </v-window>
        </v-card>
      </div>
      <template #sidebar></template>
    </sidebar-layout>
  </v-container>
  <not-found v-else icon="mdi-account-remove-outline" text="Trip not found"></not-found>
</template>

<style lang="scss" scoped></style>
