<script>
import OmniDialog from "@/components/OmniDialog.vue";
import ValidationMixin from "@/mixins/validation.mixin";
import { DateTimeMixin } from "@/mixins/date-time.mixin";
import StopChangesBlock from "@/views/trips/view/actions/components/StopChangesBlock.vue";
import { OrderStopState } from "@/data/order.ts";
import { mapActions } from "pinia";
import { useSnackbarStore } from "@/store/snackbar.store";

class Change {
  constructor(type, oldValue, newValue) {
    this.type = type;
    this.oldValue = oldValue;
    this.newValue = newValue;
  }
}

const ChangeType = {
  facility: "facility",
  date: "date",
};

export default {
  name: "RedispatchConfirmationAction",
  components: {
    StopChangesBlock,
    OmniDialog,
  },
  mixins: [ValidationMixin, DateTimeMixin],
  inheritAttrs: false,
  props: {
    trip: Object,
    route: Object,
  },
  emits: ["dispatched"],
  data() {
    return {
      showDialog: false,
      loading: false,
      changes: [],
    };
  },
  computed: {
    changedStops() {
      return this.route.routeStops.filter((stop) => stop.state === OrderStopState.updated);
    },
    ChangeType() {
      return ChangeType;
    },
  },
  async created() {
    await this.enrichData();
    this.collectChanges();
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showSnackbarError"]),
    async enrichData() {
      for (const stop of this.changedStops) {
        if (stop.oldFacilityId) {
          await this.setOldFacility(stop);
        }
      }
    },
    collectChanges() {
      for (const stop of this.changedStops) {
        if (stop.oldFacilityId) {
          this.changes.push(new Change(ChangeType.facility, stop.$oldFacility, stop.facility));
        }
      }
      console.log(this.changes);
    },
    showRedispatchConfirmation() {
      this.showDialog = true;
    },
    async confirmRedispatch() {
      this.loading = true;
      const response = await this.$api.trips.confirmRouteRedispatch(this.trip.id, this.route.id);
      if (response.success) {
        this.$emit("dispatched");
        this.showDialog = false;
      } else {
        this.showSnackbarError("Error re-dispatching route");
      }
      this.loading = false;
    },
    async setOldFacility(stop) {
      const res = await this.$api.company.findFacilityById(stop.oldFacilityId);
      if (res.success) {
        stop.$oldFacility = res.data;
      }
    },
  },
};
</script>

<template>
  <v-btn
    block
    class="text-uppercase font-weight-600"
    color="primary"
    v-bind="$attrs"
    variant="flat"
    @click="showRedispatchConfirmation"
  >
    Confirm re-dispatch
  </v-btn>
  <omni-dialog
    v-model="showDialog"
    :disabled="loading"
    :loading="loading"
    primary-action-label="Save"
    title="Re-dispatch confirmation"
    @primary-action:click="confirmRedispatch"
  >
    <div class="pb-4 text-grey-darken-1">
      You are about to confirm changes of dispatch for
      <router-link
        :to="{ name: 'truck-view', params: { id: route.$truck?.id } }"
        class="text-primary text-decoration-none"
        data-qa="modal-truck-number"
        target="_blank"
      >
        Truck #{{ route.$truck?.number }}
      </router-link>
      :
    </div>
    <ul v-if="changes.length > 0" class="mb-4">
      <li
        v-for="(change, i) in changes"
        :key="i"
        class="ml-4 font-weight-500 text-grey-darken-2"
        data-qa="modal-changed-facility"
      >
        <template v-if="change.type === ChangeType.facility">
          Changed facility from “{{ change.oldValue.name }}” to “{{ change.newValue.name }}”
        </template>
      </li>
    </ul>
    <stop-changes-block v-for="stop in changedStops" :key="stop.id" :stop="stop" />
  </omni-dialog>
</template>

<style lang="scss" scoped></style>
