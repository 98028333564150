<script>
import OmniDialog from "@/components/OmniDialog.vue";

export default {
  name: "AdditionalActions",
  components: { OmniDialog },
  props: {
    isArchived: {
      type: Boolean,
      default: false,
    },
    archiveConfirmationTitle: String,
    archiveConfirmationText: String,
    unarchiveConfirmationTitle: String,
    unarchiveConfirmationText: String,
  },
  emits: ["archive", "unarchive"],
  data() {
    return {
      showArchiveConfirmationDialog: false,
      showUnarchiveConfirmationDialog: false,
      archiveErrorSummary: null,
      archiveErrors: null,
    };
  },
  methods: {
    archive() {
      this.$emit("archive", this.onArchiveSuccess, this.onArchiveError);
    },
    onArchiveSuccess() {
      this.hideArchiveConfirmation();
    },
    onArchiveError(summary, errors) {
      this.archiveErrorSummary = summary;
      this.archiveErrors = errors;
    },
    unarchive() {
      this.$emit("unarchive", this.onUnarchiveSuccess, this.onArchiveError);
    },
    onUnarchiveSuccess() {
      this.hideUnarchiveConfirmation();
    },
    showArchiveConfirmation() {
      this.showArchiveConfirmationDialog = true;
    },
    showUnarchiveConfirmation() {
      this.showUnarchiveConfirmationDialog = true;
    },
    hideArchiveConfirmation() {
      this.showArchiveConfirmationDialog = false;
    },
    hideUnarchiveConfirmation() {
      this.showUnarchiveConfirmationDialog = false;
    },
  },
};
</script>

<template>
  <v-menu location="top start" offset="4">
    <template #activator="{ props }">
      <v-btn class="actions--btn" v-bind="props" variant="plain">
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item v-if="!isArchived" class="cursor-pointer" @click="showArchiveConfirmation">
        <v-list-item-title>
          <v-icon class="mr-2">mdi-archive-arrow-down-outline</v-icon>
          Archive
        </v-list-item-title>
      </v-list-item>
      <v-list-item v-if="isArchived" class="cursor-pointer" @click="showUnarchiveConfirmation">
        <v-list-item-title>
          <v-icon class="mr-2">mdi-archive-arrow-up-outline</v-icon>
          Unarchive
        </v-list-item-title>
      </v-list-item>
      <slot></slot>
    </v-list>
  </v-menu>
  <omni-dialog
    v-model="showArchiveConfirmationDialog"
    :disabled="!!archiveErrors"
    :title="archiveConfirmationTitle"
    persistent
    primary-action-label="Archive"
    size="small"
    @primary-action:click="archive"
  >
    <div v-if="!archiveErrors">{{ archiveConfirmationText }}</div>
    <div v-else class="text-error">
      {{ archiveErrorSummary }}
      <ul class="ml-6">
        <li v-for="(error, i) in archiveErrors" :key="i" class="mt-1">
          {{ error.message }}
          <!-- Trucks -->
          <template v-if="error.trucks">
            <template v-for="(truck, j) in error.trucks" :key="truck.id">
              <router-link
                :to="{ name: 'truck-view', params: { id: truck.id } }"
                class="text-error font-weight-600 white-space-nowrap"
                target="_blank"
              >
                {{ truck.number }}
              </router-link>
              <template v-if="j < error.trucks.length - 1">,&nbsp;</template>
            </template>
          </template>
          <!-- Drivers -->
          <template v-if="error.drivers">
            <template v-for="(driver, j) in error.drivers" :key="driver.id">
              <router-link
                :to="{ name: 'user-view', params: { id: driver.id } }"
                class="text-error font-weight-600 white-space-nowrap"
                target="_blank"
              >
                {{ driver.name }}
              </router-link>
              <template v-if="j < error.drivers.length - 1">,&nbsp;</template>
            </template>
          </template>
          <!-- Contact persons -->
          <template v-if="error.contactPersons">
            <template v-for="(contactPerson, j) in error.contactPersons" :key="contactPerson.id">
              <router-link
                :to="{ name: 'user-view', params: { id: contactPerson.id } }"
                class="text-error font-weight-600 white-space-nowrap"
                target="_blank"
              >
                {{ contactPerson.name }}
              </router-link>
              <template v-if="j < error.contactPersons.length - 1">,&nbsp;</template>
            </template>
          </template>
          <!-- Coordinators -->
          <template v-if="error.coordinators">
            <template v-for="(coordinator, j) in error.coordinators" :key="coordinator.id">
              <router-link
                :to="{ name: 'user-view', params: { id: coordinator.id } }"
                class="text-error font-weight-600 white-space-nowrap"
                target="_blank"
              >
                {{ coordinator.name }}
              </router-link>
              <template v-if="j < error.coordinators.length - 1">,&nbsp;</template>
            </template>
          </template>
          <!-- Owners -->
          <template v-if="error.owners">
            <template v-for="(owner, j) in error.owners" :key="owner.id">
              <router-link
                :to="{ name: 'user-view', params: { id: owner.id } }"
                class="text-error font-weight-600 white-space-nowrap"
                target="_blank"
              >
                {{ owner.name }}
              </router-link>
              <template v-if="j < error.owners.length - 1">,&nbsp;</template>
            </template>
          </template>
        </li>
      </ul>
    </div>
  </omni-dialog>
  <omni-dialog
    v-model="showUnarchiveConfirmationDialog"
    :title="unarchiveConfirmationTitle"
    persistent
    primary-action-label="Unarchive"
    size="small"
    @primary-action:click="unarchive"
  >
    <div v-if="!archiveErrors">{{ unarchiveConfirmationText }}</div>
    <div v-else class="text-error">
      {{ archiveErrorSummary }}
      <ul class="ml-6">
        <li v-for="(error, i) in archiveErrors" :key="i" class="mt-1">
          {{ error.message }}
        </li>
      </ul>
    </div>
  </omni-dialog>
</template>

<style lang="scss" scoped>
.actions--btn {
  width: 36px;
  min-width: 0;
}
</style>
