<script>
import OmniSelect from "@/components/controls/OmniSelect.vue";
import OmniDialog from "@/components/OmniDialog.vue";
import ValidationMixin from "@/mixins/validation.mixin";
import BaseView from "@/views/BaseView.vue";
import { mapActions } from "pinia";
import { useSnackbarStore } from "@/store/snackbar.store";

export default {
  name: "DispatcherEditDialog",
  components: { OmniDialog, OmniSelect },
  mixins: [BaseView, ValidationMixin],
  props: {
    modelValue: Boolean,
    dispatcher: {
      type: Object,
      required: true,
    },
  },
  emits: ["update:modelValue", "change", "remove"],
  data() {
    return {
      model: null,
    };
  },
  computed: {
    showDialog: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    dialogTitle() {
      return this.dispatcher?.firstName
        ? "Dispatcher " + this.dispatcher.firstName + " " + this.dispatcher.lastName
        : "New dispatcher";
    },
  },
  watch: {
    dispatcher() {
      this.model = { ...this.dispatcher };
    },
  },
  mounted() {
    this.model = { ...this.dispatcher };
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showSnackbarError", "showSnackbarSuccess"]),
    async save() {
      const result = await this.$refs.form.validate();
      if (!result.valid) return;

      const resp = await this.$api.dispatchers.upsertDispatcher(this.model);
      if (resp.success) {
        this.$emit("change");
        this.showDialog = false;
        this.showSnackbarSuccess(this.$t("general.saved"));
      } else {
        this.showSnackbarError(this.$t("general.save-error"));
      }
    },
    async remove() {
      const resp = await this.$api.dispatchers.removeDispatcher(this.model.id);
      if (resp.success) {
        this.$emit("change");
        this.showDialog = false;
      } else {
        this.showSnackbarError(this.$t("general.save-error"));
      }
    },
  },
};
</script>

<template>
  <omni-dialog v-model="showDialog" :title="dialogTitle" primary-action-label="Save" @primary-action:click="save">
    <v-form ref="form" lazy-validation>
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="model.firstName"
            :label="$t('general.first-name')"
            :rules="[requiredValidator]"
            autocomplete="off1"
            color="primary"
            density="compact"
            hide-details="auto"
            variant="outlined"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="model.lastName"
            :label="$t('general.last-name')"
            :rules="[requiredValidator]"
            autocomplete="off1"
            color="primary"
            density="compact"
            hide-details="auto"
            variant="outlined"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="model.email"
            :disabled="!!model.id"
            :label="$t('general.email')"
            :multiple="true"
            :rules="[requiredValidator, emailValidator]"
            color="primary"
            density="compact"
            hide-details="auto"
            type="email"
            variant="outlined"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <omni-select
            v-model="model.roles"
            :items="roles"
            :label="$t('dispatchers.roles')"
            :multiple="true"
            :rules="[selectValidator]"
            item-title="name"
            item-value="id"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="model.phone"
            :label="$t('general.phone')"
            autocomplete="off1"
            color="primary"
            density="compact"
            hide-details="auto"
            type="phone"
            variant="outlined"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="model.phoneExt"
            :label="$t('dispatchers.phone-ext')"
            autocomplete="off1"
            color="primary"
            density="compact"
            hide-details="auto"
            type="phone"
            variant="outlined"
          />
        </v-col>
      </v-row>
    </v-form>
    <template #actions:custom>
      <v-btn
        v-if="model.id && !model.emailConfirmed"
        id="delete-btn"
        class="font-weight-600 text-uppercase px-4"
        color="error"
        variant="flat"
        @click="remove"
      >
        {{ $t("general.delete") }}
      </v-btn>
    </template>
  </omni-dialog>
</template>

<style lang="scss" scoped></style>
