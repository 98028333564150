<script>
import BaseView from "@/views/BaseView.vue";
import ChatMixin from "@/views/chats/ChatMixin.vue";
import OmniDialog from "@/components/OmniDialog.vue";
import { Role } from "@/enums/navigation";
import { mapState } from "pinia";
import { useAppStore } from "@/store/app.store";

export default {
  name: "TransferChatAction",
  components: { OmniDialog },
  mixins: [BaseView, ChatMixin],
  props: {
    thread: Object,
  },
  data() {
    return {
      dialogVisible: false,
      transferToId: null,
    };
  },
  computed: {
    ...mapState(useAppStore, ["mobile", "dispatchers"]),
    operationsFirst() {
      const operations = [],
        others = [];

      for (const dispatcher of this.dispatchers) {
        // skip current dispatcher
        if (dispatcher.id === this.dispatcher.id) continue;

        if (dispatcher.roles.includes(Role.operation)) {
          operations.push(dispatcher);
        } else {
          others.push(dispatcher);
        }
      }

      return operations.concat(others);
    },
  },
  methods: {
    transfer() {
      this.updateThread({ dispatcherId: this.transferToId });
      this.dialogVisible = false;
    },
    rolesToString(dispatcher) {
      return dispatcher.roles.map((roleId) => this.roles.find((r) => r.id === roleId).name).join(", ");
    },
  },
};
</script>

<template>
  <v-btn
    v-if="thread.dispatcherId === dispatcher.id && !thread.resolved"
    class="mr-2"
    color="primary"
    size="small"
    variant="outlined"
    @click="dialogVisible = true"
  >
    <v-icon class="text-primary mr-1"> mdi-swap-horizontal</v-icon>
    <span class="font-weight-600 text-uppercase text-primary">
      {{ $t("dispatchers.transfer") }}
    </span>
  </v-btn>

  <omni-dialog
    v-model="dialogVisible"
    primary-action-label="Transfer"
    title="Transfer chat to"
    @primary-action:click="transfer"
  >
    <div class="my-5">
      <v-autocomplete
        v-model="transferToId"
        :items="operationsFirst"
        color="primary"
        density="compact"
        hide-details
        item-title="name"
        item-value="id"
        label="Select dispatcher"
        variant="outlined"
      >
        <template #item="{ item, props }">
          <v-list-item :title="item.raw.name" v-bind="props">
            <span class="text-caption text-grey-darken-1">{{ rolesToString(item.raw) }}</span>
          </v-list-item>
        </template>
      </v-autocomplete>
    </div>
  </omni-dialog>
</template>

<style lang="scss" scoped></style>
