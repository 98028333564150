<script>
import OrderStatus from "@/components/status/OrderStatus.vue";
import { mapState } from "pinia";
import { useAppStore } from "@/store/app.store";
import ShowMore from "@/components/ShowMore.vue";

export default {
  name: "OrderViewInfo",
  components: { ShowMore, OrderStatus },
  props: {
    order: Object,
  },
  computed: {
    ...mapState(useAppStore, ["dispatchers"]),
    bookedBy() {
      if (this.dispatchers.length === 0) {
        return null;
      }
      return this.dispatchers.find((d) => d.id === this.order.bookedBy)?.name;
    },
  },
};
</script>

<template>
  <div class="user-block pt-5 pr-5 pb-7 pl-5 bg-white rounded mt-4">
    <v-row>
      <v-col lg="6">
        <div class="text-grey-darken-3 mb-6 font-weight-medium">Order information</div>
        <v-row>
          <div class="label long">
            <span class="text-grey-darken-1">Status</span>
          </div>
          <div class="description">
            <order-status :order="order"></order-status>
          </div>
        </v-row>
        <v-row>
          <div class="label long">
            <span class="text-grey-darken-1">Booked by</span>
          </div>
          <div class="description text-primary" data-qa="booked-by">
            {{ bookedBy }}
          </div>
        </v-row>
        <v-row>
          <div class="label long">
            <span class="text-grey-darken-1">Order rate</span>
          </div>
          <div class="description" data-qa="order-rate">
            {{ $filters.price(order.rate) }}
          </div>
        </v-row>
        <v-row>
          <div class="label long">
            <span class="text-grey-darken-1">Bill to company</span>
          </div>
          <div class="description" data-qa="bill-to-company">
            <router-link
              :to="{ name: 'customer-edit', params: { id: order.customer?.id } }"
              class="text-decoration-none text-primary"
            >
              {{ order.customer?.name }}
            </router-link>
          </div>
        </v-row>
        <v-row>
          <div class="label long">
            <span class="text-grey-darken-1">Ref number</span>
          </div>
          <div class="description" data-qa="ref-number">
            <show-more v-if="order.refNumbers" :items="order.refNumbers"></show-more>
          </div>
        </v-row>
        <v-row>
          <div class="label long">
            <span class="text-grey-darken-1">Send updates to</span>
          </div>
          <div class="description" data-qa="e-mail">
            <template v-for="(email, index) in order.sendUpdatesTo" :key="index">
              <span v-clipboard="email">
                {{ email }}<template v-if="index < order.sendUpdatesTo.length - 1">, </template>
              </span>
            </template>
          </div>
        </v-row>
      </v-col>
      <v-col lg="6">
        <v-row>
          <div class="label">
            <span class="text-grey-darken-1">Check in as</span>
          </div>
          <div class="description" data-qa="check-in-as">
            {{ order.checkInAs }}
          </div>
        </v-row>
        <v-row>
          <div class="label">
            <span class="text-grey-darken-1">Required certificates</span>
          </div>
          <div class="description" data-qa="required-certificates">
            {{ order.requiredDriverCertificates?.map((c) => c.toUpperCase()).join(", ") }}
          </div>
        </v-row>
        <v-row>
          <div class="label">
            <span class="text-grey-darken-1">Temperature control</span>
          </div>
          <div class="description text-grey-darken-1 font-weight-500 font-size-12 d-flex align-center">
            <template v-if="order.protectFromFreezing || order.temperatures?.length > 0">
              <v-icon class="mr-2" color="blue" size="16">mdi-snowflake</v-icon>
              <template v-if="order.protectFromFreezing">
                <span data-qa="protect-from-freezing">Protect from freezing</span>
              </template>
              <template v-else-if="order.temperatures?.length > 0">
                <span data-qa="temperature-from">{{ order.temperatures[0] }}</span>
                <v-icon size="14">mdi-temperature-fahrenheit</v-icon>
                <span data-qa="temperature-to">&nbsp;to&nbsp;{{ order.temperatures[1] }}</span>
                <v-icon size="14">mdi-temperature-fahrenheit</v-icon>
              </template>
            </template>
          </div>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<style lang="scss" scoped></style>
