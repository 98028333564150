<script lang="ts" setup>
import UpdateIndicator from "@/views/trips/components/UpdateIndicator.vue";
import type { OrderFacilityDto } from "@/api/orders/dto/order.dto";
import { computed } from "vue";

const props = defineProps<{
  facility: OrderFacilityDto;
  updated: boolean;
}>();

const fullAddress = computed(() => {
  const chunks: string[] = [];
  if (props.facility.addressLine) {
    chunks.push(props.facility.addressLine);
  }
  if (props.facility.address) {
    chunks.push(props.facility.address);
  }
  return chunks.join(", ");
});
</script>

<template>
  <div>
    <div class="d-flex align-center mb-2">
      <router-link
        :to="{ name: 'facilities-edit', params: { id: props.facility.id } }"
        class="text-uppercase subheading font-weight-medium text-primary d-block text-decoration-none"
        data-qa="facility-name"
        target="_blank"
      >
        {{ props.facility.name }}
      </router-link>
      <update-indicator v-if="props.updated" class="ml-1"></update-indicator>
    </div>
    <div class="text-grey-darken-2" data-qa="facility-address">
      {{ fullAddress }}
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
