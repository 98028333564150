<script>
import { FacilityType } from "@/data/facility";
import GoogleMapsMixin from "@/mixins/google-maps.mixin";
import LocationMixin from "@/mixins/location.mixin";
import BaseView from "@/views/BaseView.vue";

export default {
  name: "CalculatingLocationBlock",
  mixins: [BaseView, GoogleMapsMixin, LocationMixin],
  props: {
    facility: null,
  },
  emits: ["update:state"],
  data() {
    return {
      reversedLocation: null,
    };
  },
  computed: {
    isStandard() {
      return this.facility.type === FacilityType.standard;
    },
    isPoint() {
      return this.facility.type === FacilityType.point;
    },
    isToBeDiscussed() {
      return this.facility.type === FacilityType.toBeDiscussed;
    },
    isManual() {
      return this.facility.type === FacilityType.manual;
    },
    hasAddressCoordinates() {
      return (
        this.facility.addressCoordinates && this.facility.addressCoordinates.lon && this.facility.addressCoordinates.lat
      );
    },
    reverseGeoCode() {
      return this.debounce(async () => {
        const response = await this.$api.geo.reverse(
          this.facility.addressCoordinates.lat,
          this.facility.addressCoordinates.lon,
        );
        if (response.success) {
          this.reversedLocation = response.data;
          this.$emit("update:state", this.reversedLocation.address.state);
        }
      }, 500);
    },
  },
  watch: {
    async "facility.addressCoordinates"(value) {
      if (!this.isManual) return;
      this.reversedLocation = null;
      if (value[0] && value[1]) {
        this.reverseGeoCode();
      }
    },
    facility: {
      handler() {
        if (this.isPoint && this.facility.address) {
          const location = this.expandLocation(this.facility.address);
          if (location) {
            this.$emit("update:state", location.state);
          }
        }
      },
      deep: true,
    },
  },
};
</script>

<template>
  <div class="bg-blue-grey-lighten-5 pa-5 rounded">
    <div class="caption text-grey-darken-1 mb-4">Location for calculating loaded miles (system):</div>
    <div v-if="isStandard || isPoint || isToBeDiscussed">
      <template v-if="facility.address">
        <div class="mb-2 font-size-16 d-flex align-center">
          {{ facility.address }}
          <div v-if="facility.$locationMatchError" class="text-error font-size-14 d-flex align-center">
            <v-icon class="mx-2" icon="mdi-alert-circle-outline" size="16"></v-icon>
            States must be the same
          </div>
        </div>
        <a
          :href="getPlaceUrl(facility.addressCoordinates)"
          class="font-size-11 text-success d-flex align-center text-decoration-none"
          target="_blank"
        >
          <v-icon class="mr-2" icon="mdi-map-marker-radius-outline"></v-icon>
          <div class="text-decoration-underline">
            Lat: {{ facility.addressCoordinates.lat }} Lon: {{ facility.addressCoordinates.lon }}
          </div>
        </a>
      </template>
      <template v-else>
        <div class="mb-2">-</div>
        <div class="font-size-11 text-grey-darken-1 d-flex align-center">
          <v-icon class="mr-2" icon="mdi-map-marker-radius-outline"></v-icon>
          <div class="text-decoration-underline">Lat: ... Lon: ...</div>
        </div>
      </template>
    </div>
    <div v-if="isManual">
      <template v-if="hasAddressCoordinates">
        <div class="d-flex align-center mb-2">
          <div class="text-grey-darken-3 font-size-16">
            {{ facility.addressCoordinates.lat }}, {{ facility.addressCoordinates.lon }}
          </div>
          <a :href="getPlaceUrl(facility.addressCoordinates)" class="ml-2" target="_blank">
            <v-icon color="success" icon="mdi-map-marker-radius-outline"></v-icon>
          </a>
        </div>
        <div v-if="reversedLocation" class="font-size-14 text-grey-darken-1 d-flex align-center">
          {{ reversedLocation.fullAddress }}
          <div v-if="facility.$locationMatchError" class="text-error font-size-14 d-flex align-center">
            <v-icon class="mx-2" icon="mdi-alert-circle-outline" size="16"></v-icon>
            States must be the same
          </div>
        </div>
      </template>
      <div v-else>-</div>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
