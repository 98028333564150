import { createApp } from "vue";
import * as Sentry from "@sentry/vue";

import API from "@/api/api";
import { router } from "@/router";
import { FilterUtils } from "@/filters";

import Storage from "@/plugins/storage";
import WS from "@/plugins/ws.connection";
import i18n from "@/plugins/i18n";
import vuetify from "@/plugins/vuetify";
import dayjs from "@/plugins/dayjs";
import { plugin as EqualHeights } from "@morev/equal-heights/vue";

import "@vuepic/vue-datepicker/dist/main.css";

import "@/assets/style/fonts.css";
import "@/assets/style/color.scss";
import "@/assets/style/buttons.scss";
import "@/assets/style/style.scss";

import clipboard from "@/directives/clipboard";

import App from "@/App.vue";
import CabinetLayout from "@/views/layout/Cabinet.vue";
import BlankLayout from "@/views/layout/Blank.vue";
import { vMaska } from "maska";
import VueDatePicker from "@vuepic/vue-datepicker";
import { createPinia } from "pinia";

const app = createApp(App);

const pinia = createPinia();
app.use(pinia);

app.config.globalProperties.$filters = new FilterUtils();

app.component("CabinetLayout", CabinetLayout);
app.component("BlankLayout", BlankLayout);
app.component("VueDatePicker", VueDatePicker);

app.directive("clipboard", clipboard);
app.directive("maska", vMaska);

app.use(vuetify);
app.use(router);
app.use(i18n);
app.use(dayjs);
app.use(EqualHeights);

app.use(API);
app.use(Storage);
app.use(WS);

if (import.meta.env.PROD) {
  const sentryDsn = "https://547c0ebda59f4f6e981e94ad74b09172@o1214925.ingest.sentry.io/6356354";
  let environment = "production"; // prod
  if (window.location.hostname.indexOf("dev") > -1) {
    environment = "development"; // dev
  }

  Sentry.init({
    app,
    dsn: sentryDsn,
    tracePropagationTargets: ["dev.omni-dispatch.com", "tms.omni-dispatch.com", /^\//],
    integrations: [
      Sentry.browserTracingIntegration({
        router,
      }),

      Sentry.replayIntegration({
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: false,
      }),
      Sentry.replayCanvasIntegration({
        enableManualSnapshot: true,
      }),
    ],
    tracesSampleRate: 0.2,
    replaysOnErrorSampleRate: 1.0,
    environment: environment,
  });
}
router.isReady().then(() => {
  app.mount("#app");
});
