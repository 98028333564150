<script>
import TrucksMixin from "@/views/trucks/TrucksMixin.vue";
import OmniTabs from "@/components/controls/OmniTabs.vue";
import TruckStatus from "@/components/status/TruckStatus.vue";
import TruckLocationInfo from "@/views/trucks/components/TruckLocationInfo.vue";
import PhoneNumber from "@/components/PhoneNumber.vue";
import UserStatus from "@/components/status/UserStatus.vue";
import OmniDialog from "@/components/OmniDialog.vue";
import UsersMixin from "@/views/users/UsersMixin.vue";

export default {
  name: "AttachTruckDialog",
  components: { OmniDialog, UserStatus, PhoneNumber, TruckLocationInfo, TruckStatus, OmniTabs },
  mixins: [TrucksMixin, UsersMixin],
  emits: ["attach"],
  data() {
    return {
      carrierName: "",
      carriers: [],

      tab: "truck",

      truckNumber: "",
      trucks: [],

      notAvailableTruck: null,
      showNotAvailableConfirmation: false,
    };
  },
  async created() {
    await this.loadTrucks();
  },
  methods: {
    calculateAttachDisabled(truck) {
      if (truck.status === this.truckStatusOnHold) return 'Truck is "On hold" and can\'t be attached';
      if (truck.archived !== null) return "Truck now is in archive and can't be attached";
      if (truck.trailerId === null) return "Truck doesn't have a trailer and can't be attached";

      if (truck.owner.ownerStatus === this.ownerStatusOnHold)
        return `Owner ${truck.owner.name} is "On hold" and truck can't be attached`;
      if (truck.driverId === null) return "There is no first driver and truck can't be attached";
      if (truck.driver?.driverStatus === this.driverStatusOnHold)
        return `First driver ${truck.driver.name} is on "On hold" and truck can't be attached`;

      return false;
    },
    async loadTrucks() {
      if (this.truckNumber.length < 2 && this.trucks.length > 0) return;

      const params = { pageSize: 3 };
      if (this.truckNumber) params.number = this.truckNumber;

      try {
        const resp = await this.$api.trucks.findTrucks(params);
        this.trucks = resp.data.items.map((truck) => {
          truck.attachDisabled = this.calculateAttachDisabled(truck);
          return truck;
        });
      } catch (error) {
        console.error("Error load trucks list: ", error);
      }
    },
    attach(truck) {
      if (truck.status === this.truckStatusNotAvailable) {
        if (this.notAvailableTruck === null) {
          // show confirmation dialog
          this.showNotAvailableConfirmation = true;
          this.notAvailableTruck = truck;
        } else {
          // on accept hide confirmation dialog & attach truck
          this.showNotAvailableConfirmation = false;
          this.$emit("attach", { truck: this.notAvailableTruck });
        }
        return;
      }
      this.$emit("attach", { truck });
    },
  },
};
</script>

<template>
  <omni-tabs v-model="tab" align-tabs="start" class="mt-4">
    <v-tab value="truck">Truck</v-tab>
  </omni-tabs>
  <v-window v-model="tab" class="pt-2">
    <v-window-item value="truck">
      <v-card class="pt-2 pb-4" variant="flat">
        <div>
          <v-text-field
            v-model="truckNumber"
            class="mb-3 body-1"
            data-qa="modal-truck-number-field"
            density="compact"
            hide-details
            label="Truck number"
            variant="outlined"
            @blur="loadTrucks"
            @keyup.enter="loadTrucks"
          />
          <v-row class="blocks">
            <v-col v-for="truck in trucks" :key="truck.id" cols="4">
              <div class="block height-100" data-qa="modal-truck-block">
                <div class="block-inner pt-5 pr-5 pl-5 pb-3 rounded height-100">
                  <div class="block-label" data-qa="modal-truck-status">
                    <truck-status :truck="truck"></truck-status>
                  </div>
                  <div class="block-inner__content">
                    <div class="block__header mb-3">
                      <router-link
                        :to="{ name: 'truck-view', params: { id: truck.id } }"
                        class="text-primary subheading mb-1 font-weight-medium text-decoration-none"
                        data-qa="modal-truck-number"
                        target="_blank"
                        >Truck #{{ truck.number }}
                      </router-link>
                      <div class="d-flex justify-space-between">
                        <span class="text-grey-darken-1 font-size-12" data-qa="modal-truck-type">{{
                          getTruckType(truck.type)
                        }}</span>
                        <span v-if="truck.trailer" class="text-grey-darken-3 font-size-12">
                          {{ getTruckSize(truck) }}
                        </span>
                      </div>
                    </div>
                    <div class="block__body mb-6">
                      <truck-location-info :location="truck.currentLocation" :time="truck.currentLocationTime" />
                    </div>

                    <!-- show driver info -->
                    <div v-if="truck.driver" class="block__footer mb-6">
                      <div class="block__footer__info">
                        <div class="d-flex align-center mb-2">
                          <v-tooltip location="top">
                            <template #activator="{ props }">
                              <v-btn
                                class="status-item text-uppercase text-white mr-2 font-weight-600 bg-indigo-lighten-1"
                                data-qa="modal-truck-driver-role"
                                v-bind="props"
                                variant="tonal"
                              >
                                {{ $t("users.dr") }}
                              </v-btn>
                            </template>
                            <span class="text-body-2">{{ $t("users.driver") }}</span>
                          </v-tooltip>
                          <span
                            v-if="truck.driver.id === truck.driver.contactPersonId"
                            class="currency-icon mr-2 small"
                          />
                          <router-link
                            :to="{ name: 'user-view', params: { id: truck.driver.id } }"
                            class="text-primary text-decoration-none mr-2 line-height-1"
                            data-qa="modal-truck-driver-name"
                          >
                            {{ truck.driver.name }}
                          </router-link>
                          <user-status :status="truck.driver.driverStatus" />
                        </div>
                        <div class="d-flex align-center">
                          <phone-number
                            :is-callable="false"
                            :number="truck.driver.phone"
                            class="font-size-12 text-decoration-none text-primary"
                            data-qa="modal-truck-driver-phone"
                          />
                          <span
                            class="ml-2 text-uppercase bg-grey-lighten-4 rounded text-grey-darken-1 pr-1 pl-1 caption type-language"
                            data-qa="modal-truck-driver-language"
                          >
                            {{ $filters.upper(truck.driver.language) }}
                          </span>
                        </div>
                      </div>
                    </div>

                    <!-- show contact person info -->
                    <div
                      v-if="truck.driver && truck.driver.contactPersonId !== truck.driver.id"
                      class="block__footer mb-6"
                    >
                      <div class="block__footer__info">
                        <div class="d-flex align-center mb-2">
                          <span class="currency-icon mr-2 small" />
                          <router-link
                            :to="{ name: 'user-view', params: { id: truck.driver.contactPersonId } }"
                            class="text-primary text-decoration-none mr-2 line-height-1"
                            data-qa="modal-truck-contact-persone-name"
                          >
                            {{ truck.driver.contactPerson?.name }}
                          </router-link>
                        </div>
                        <div class="d-flex align-center">
                          <phone-number
                            :is-callable="false"
                            :number="truck.driver.contactPerson?.phone"
                            class="font-size-12 text-decoration-none text-primary"
                            data-qa="modal-truck-contact-persone-phone"
                          />
                          <span
                            class="ml-2 text-uppercase bg-grey-lighten-4 rounded text-grey-darken-1 pr-1 pl-1 caption type-language"
                            data-qa="modal-truck-contact-persone-language"
                          >
                            {{ $filters.upper(truck.driver.contactPerson?.language) }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <v-tooltip :disabled="!truck.attachDisabled" location="top">
                    <template #activator="{ props }">
                      <div v-bind="props">
                        <v-btn
                          :disabled="!!truck.attachDisabled"
                          class="font-weight-600 text-uppercase width-100"
                          color="primary"
                          variant="flat"
                          @click="attach(truck)"
                        >
                          Attach
                        </v-btn>
                      </div>
                    </template>
                    <span class="text-body-2" data-qa="modal-truck-alert-tooltip">{{ truck.attachDisabled }}</span>
                  </v-tooltip>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>

        <omni-dialog
          v-model="showNotAvailableConfirmation"
          :title="`Attach truck #${notAvailableTruck?.number}`"
          primary-action-label="Attach"
          size="small"
          @primary-action:click="attach(notAvailableTruck)"
        >
          <div class="text-body-2 text-left text-grey text-grey-darken-1 py-4">
            Truck #{{ notAvailableTruck?.number }} has "Not available" truck status, are you sure want to attach this
            truck?
          </div>
        </omni-dialog>
      </v-card>
    </v-window-item>
  </v-window>
</template>

<style>
.status-item {
  font-size: 9px;
  line-height: 16px;
  height: 16px !important;
  border-radius: 100px;
  padding: 0 2px !important;
  min-width: auto !important;
}

.block-inner {
  position: relative;
  background: #f8f9fa;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.block-label {
  position: absolute;
  top: 12px;
  right: 12px;
  padding: 2px 8px;
}

.type-language {
  line-height: 12px;
  font-size: 11px;
  padding: 2px 0;
}

.line-height-1 {
  line-height: 1 !important;
}

.currency-icon {
  width: 18px;
  flex: 0 0 18px;
  height: 18px;
  font-size: 13px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  background: url(@/assets/images/currency-icon.svg) no-repeat;
  background-size: 18px;

  &.small {
    width: 14px;
    flex: 0 0 14px;
    height: 14px;
    background-size: 14px;
  }
}
</style>
