<script>
import OrderStopsRoute from "@/views/orders/components/OrderStopsRoute.vue";
import OmniDialog from "@/components/OmniDialog.vue";
import AttachTruckDialog from "@/views/trips/edit/dialogs/AttachTruckDialog.vue";
import TravelOrderStatus from "@/components/status/TravelOrderStatus.vue";
import TruckDriverInfo from "@/views/orders/components/view/TruckDriverInfo.vue";
import GoogleMapsMixin from "@/mixins/google-maps.mixin";

export default {
  name: "OrderViewSidebar",
  components: {
    TruckDriverInfo,
    TravelOrderStatus,
    AttachTruckDialog,
    OmniDialog,
    OrderStopsRoute,
  },
  mixins: [GoogleMapsMixin],
  props: {
    order: Object,
  },
  data() {
    return {
      attachTruckDialog: false,
      showRoute: false,
    };
  },
  computed: {
    travelOrders() {
      if (!this.order.trip || !this.order.trip.routes) return [];

      const travelOrders = [];
      for (const route of this.order.trip.routes) {
        for (const travelOrder of route.travelOrders) {
          // skip travel orders for other orders
          if (travelOrder.orderId !== this.order.id) continue;
          travelOrders.push({ ...travelOrder, truck: route.truck });
        }
      }
      return travelOrders;
    },
  },
  methods: {
    attachToOrder(payload) {
      this.$router.push({
        name: "trip-edit",
        params: { id: "new" },
        query: { order: this.order.id, truck: payload.truck?.id },
      });
    },
  },
};
</script>

<template>
  <div class="flex-1 pa-5">
    <div class="text-grey-darken-3 mb-8 font-weight-medium text-subtitle-1">Information</div>
    <div class="mb-3">
      <div class="d-flex block align-center flex-wrap mb-2">
        <span class="font-weight-medium text--lighten-1 mr-4" data-qa="trip">
          Trip:
          <router-link
            :to="{ name: 'trip-view', params: { id: order.tripId } }"
            class="text-decoration-none text-primary"
            target="_blank"
          >
            #{{ order.tripId }}
          </router-link>
        </span>
      </div>
    </div>
    <div v-for="travelOrder in travelOrders" :key="travelOrder.id" class="mb-3 block">
      <div class="d-flex align-center justify-space-between flex-wrap mb-2">
        <div>
          <router-link
            :to="{ name: 'trip-view', params: { id: order.tripId } }"
            class="mr-3 text-decoration-none text-primary"
            data-qa="travel_order-number"
            target="_blank"
          >
            TO #{{ travelOrder.id }}
          </router-link>
          <travel-order-status :travel-order="travelOrder"></travel-order-status>
        </div>
        <div>Driver rate: ${{ travelOrder.rate }}</div>
      </div>
      <div class="d-flex align-center justify-space-between mb-1">
        <div>
          <v-icon class="mr-2" color="primary" icon="mdi-truck-outline" size="16"></v-icon>
          <router-link
            :to="{ name: 'truck-view', params: { id: travelOrder.truck?.id } }"
            class="text-decoration-none text-primary mr-2 font-size-12"
            >Truck #{{ travelOrder.truck?.number }}
          </router-link>
        </div>
        <div>
          <v-tooltip location="top">
            <template #activator="{ props }">
              <v-icon v-if="order.teamForBroker" size="small" v-bind="props">mdi-account-multiple</v-icon>
            </template>
            Team for broker
          </v-tooltip>
          <v-tooltip location="top">
            <template #activator="{ props }">
              <v-icon
                v-if="order.underTracking"
                class="ml-2"
                color="success"
                icon="mdi-map-marker-outline"
                size="small"
                v-bind="props"
              ></v-icon>
            </template>
            Under tracking
          </v-tooltip>
        </div>
      </div>
      <div class="d-flex align-center mb-1">
        {{ travelOrder.truck?.currentLocation.fullAddress }}
        <a
          v-if="travelOrder.truck?.currentLocation.coordinates"
          :href="getPlaceUrl(travelOrder.truck?.currentLocation.coordinates)"
          target="_blank"
        >
          <v-tooltip location="top">
            <template #activator="{ props }">
              <v-icon
                class="text-green text--green-accent-2 ml-2"
                icon="mdi-map-marker-radius-outline"
                size="16"
                v-bind="props"
              ></v-icon>
            </template>
            Current location
          </v-tooltip>
        </a>
      </div>
      <truck-driver-info :driver="travelOrder.truck?.driver"></truck-driver-info>
    </div>
    <div class="order-actions">
      <v-btn
        class="mt-3 text-uppercase font-weight-600 width-100"
        color="primary"
        variant="outlined"
        @click="showRoute = true"
      >
        View route
      </v-btn>
      <v-btn
        v-if="!order.tripId"
        class="mt-3 text-uppercase font-weight-600 width-100"
        color="primary"
        variant="outlined"
        @click="attachTruckDialog = true"
      >
        Attach Truck
      </v-btn>
    </div>
    <order-stops-route v-model="showRoute" :order="order"></order-stops-route>
    <omni-dialog
      v-model="attachTruckDialog"
      size="large"
      title="Attach truck/carrier"
      @close="attachTruckDialog = false"
    >
      <attach-truck-dialog @attach="attachToOrder" />
    </omni-dialog>
  </div>
</template>

<style lang="scss" scoped>
.block {
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 16px;
  margin-bottom: 16px;
}
</style>
