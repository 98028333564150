export enum RouteRedispatchState {
  normal = "normal",
  needed = "needed",
  notConfirmed = "not-confirmed",
}

export enum RouteStopState {
  normal = "normal",
  updated = "updated",
  deleted = "deleted",
}
