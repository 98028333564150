<script>
import OmniDialog from "@/components/OmniDialog.vue";

export default {
  name: "DispatcherRemoveDialog",
  components: { OmniDialog },
  props: {
    modelValue: Boolean,
    dispatcher: {
      type: Object,
      required: true,
    },
  },
  emits: ["update:modelValue", "remove"],
  computed: {
    showDialog: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    async remove() {
      const resp = await this.$api.dispatchers.removeDispatcher(this.dispatcher.id);
      if (resp.success) {
        this.$emit("remove");
        this.showDialog = false;
      } else {
        this.showSnackbarError(this.$t("general.save-error"));
      }
    },
  },
};
</script>

<template>
  <omni-dialog
    v-model="showDialog"
    primary-action-label="Delete"
    size="small"
    title="Delete dispatcher"
    @primary-action:click="remove"
  >
    Are you sure want to delete {{ dispatcher.firstName }} {{ dispatcher.lastName }}’s account?
  </omni-dialog>
</template>

<style lang="scss" scoped></style>
