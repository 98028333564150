import dayjs from "dayjs";
import "dayjs/plugin/timezone";

export enum EllipsisType {
  begin ='begin',
  middle = 'middle',
  end = 'end',
}

export class FilterUtils {
  date(value: string | null, showTime = false, timezone = null): string {
    if (!value) return "—";
    let format = "MM/DD/YYYY";
    let date = dayjs(value);
    if (showTime) {
      format += " HH:mm";
    }
    let result = date.format(format);
    if (timezone !== null) {
      date = date.tz(timezone);
      result = date.format(format) + " " + date.offsetName();
    }
    return result;
  }

  format(value: string, fractionDigits = 2): string {
    const num = parseFloat(value);
    return num !== Math.floor(num) ? num.toFixed(fractionDigits) : num.toString();
  }

  price(value: string): string {
    const num = parseFloat(value);
    return "$" + num.toFixed(2);
  }

  initials(name: string | null): string {
    if (!name) return "";
    if (name.indexOf(" ") > -1) {
      const names = name.split(" ");
      name = names[0].substring(0, 1).toUpperCase() + names[1].substring(0, 1).toUpperCase();
    } else {
      name = name.substring(0, 2).toUpperCase();
    }
    return name;
  }

  miles(value: string | null): string {
    if (value === null) return "";
    return (parseFloat(value) / 1609.344).toFixed(2);
  }

  hours(value: string | null): string {
    if (!value) return "";
    return (parseFloat(value) / 60 / 60).toFixed(2);
  }

  mdash(value: string | null): string {
    return value ? value : "-";
  }

  roundFloat(value: string, count: number = 2): string {
    if (isNaN(parseFloat(value))) return "0";
    const num = parseFloat(value);
    return num !== Math.ceil(num) ? num.toFixed(count) : num.toString();
  }

  round(n: string): string {
    return this.roundFloat(n, 2);
  }

  upper(s: string): string {
    return s ? s.toUpperCase() : "";
  }

  ellipsis(string: string, length = 40, type: EllipsisType = EllipsisType.end, ellipsis: string = "..."): string {
    if (string.length <= length) {
      return string;
    }
    const cropLength = length - ellipsis.length;
    switch (type) {
      case EllipsisType.begin:
        return ellipsis + string.slice(-cropLength);
      case EllipsisType.middle:
        return string.slice(0, Math.floor(cropLength / 2)) + ellipsis + string.slice(-Math.floor(cropLength / 2));
      case EllipsisType.end:
        return string.slice(0, cropLength) + ellipsis;
    }
  }

  bytes(bytes: number, decimals = 2) {
    if (!+bytes) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }

  booleanValue(value: boolean, trueValue = "true", falseValue = "false"): string {
    return value ? trueValue : falseValue;
  }
}

declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    $filters: FilterUtils;
  }
}
