import { ChatTab } from "@/enums/chat";
import { defineStore } from "pinia";
import type { ThreadDto } from "@/api/chat/dto/thread.dto";
import type { DispatcherDto } from "@/api/dto/dispatcher.dto";
import type { UserDto } from "@/api/dto/user.dto";
import type { MessageDto } from "@/api/chat/dto/message.dto";
import { useAppStore } from "@/store/app.store";

const DRAFT_MESSAGES_STORAGE_KEY = "_draft_messages";
const CHAT_TABS_STORAGE_KEY = "_chat_tabs";

interface ChatState {
  chatUser: any;
  threads: ChatStateThread[];
  threadsCount: ChatCounterState;
  threadsMessages: ChatCounterState;
  draftMessages: Record<string, any>;
  activeTabs: string[];
  offline: boolean;
}

interface ChatStateThread extends ThreadDto {
  counter: number;
  messages: MessageDto[];
  name: string;
  group: ChatTab;
  sorting?: boolean;
}

interface ChatCounterState {
  new: number;
  my: number;
  colleagues: number;
  archive: number;
}

const getThreadGroup = (dispatcher: DispatcherDto | undefined, thread: ThreadDto): ChatTab => {
  if (thread.resolved) {
    return ChatTab.Archive;
  }

  if (thread.dispatcherId === dispatcher?.id && !thread.resolved) {
    return ChatTab.My;
  }
  if (thread.dispatcherId && thread.dispatcherId !== dispatcher?.id) {
    return ChatTab.Colleagues;
  }
  if (!thread.dispatcherId) {
    return ChatTab.New;
  }

  return ChatTab.New;
};

export const useChatStore = defineStore("chatStore", {
  state: (): ChatState => ({
    chatUser: {},
    threads: [],
    threadsCount: { new: 0, my: 0, colleagues: 0, archive: 0 },
    threadsMessages: { new: 0, my: 0, colleagues: 0, archive: 0 },
    draftMessages: {},
    activeTabs: [],
    offline: false,
  }),
  actions: {
    initChat() {
      const storedMessages = localStorage.getItem(DRAFT_MESSAGES_STORAGE_KEY);
      if (storedMessages) {
        this.draftMessages = JSON.parse(storedMessages);
      }
      const storedTabs = localStorage.getItem(CHAT_TABS_STORAGE_KEY);
      if (storedTabs) {
        this.activeTabs = JSON.parse(storedTabs);
      }
    },
    handleWSMessage(data: any) {
      if (!data.success) {
        console.error("Websocket receives error", data.error);
        return;
      }

      switch (data.command) {
        case "pong":
          break;

        case "message":
          delete data.command;
          delete data.success;
          this.updateThreadMessage(data);
          break;

        case "messages":
          if (!data.messages) break;
          for (const msg of data.messages) {
            this.updateThreadMessage(msg);
          }
          break;

        case "thread":
          delete data.command;
          delete data.success;
          this.updateThread(data);
          this.sortThreads();
          this.recalcThreadsCount();
          break;

        case "threads":
          if (!data.threads) break;
          for (const i in data.threads) {
            this.updateThread(data.threads[i]);
          }
          if (data.total > this.threads.length) {
            console.log("Need to load more chat threads", data.total, this.threads.length);
          }
          break;

        case "token":
          // nothing to do
          break;

        default:
          console.error("Unknown command", data);
          break;
      }
    },
    setChatUser(user: UserDto) {
      this.chatUser = user;
    },
    setOffline(offline: boolean) {
      if (this.offline !== offline) {
        console.log("Change offline state", offline);
        this.offline = offline;
      }
    },
    addThreads(newThreads: ChatStateThread[]) {
      const threads = [...this.threads];
      const appStore = useAppStore();

      for (const thread of newThreads) {
        thread.counter = 0;
        thread.messages = [];
        thread.user = thread.user || {};
        thread.name = thread.user.name;
        thread.lastMessage = thread.lastMessage || "";
        thread.group = getThreadGroup(appStore.dispatcher, thread);
        threads.push(thread);
      }

      this.threads = threads;
    },
    sortThreads() {
      const threads = [...this.threads];

      threads.sort(function (a, b) {
        if (a.lastMessageDt && b.lastMessageDt && a.lastMessageDt < b.lastMessageDt) return 1; // desc
        if (a.lastMessageDt && b.lastMessageDt && a.lastMessageDt > b.lastMessageDt) return -1; // desc
        return 0;
      });

      this.threads = threads;
    },
    recalcThreadsCount() {
      const counts = { n: 0, m: 0, c: 0, a: 0 },
        newMessages = { n: 0, m: 0, c: 0, a: 0 };

      this.threads.forEach((thread) => {
        counts[thread.group]++;
        newMessages[thread.group] += thread.newMessagesCount > 0 ? 1 : 0;
      });

      // update threads count in state
      this.threadsCount.new = counts.n;
      this.threadsCount.my = counts.m;
      this.threadsCount.colleagues = counts.c;
      this.threadsMessages.new = newMessages.n;
      this.threadsMessages.my = newMessages.m;
      this.threadsMessages.colleagues = newMessages.c;
    },
    updateThread(thread: ChatStateThread) {
      const appStore = useAppStore();
      thread.group = getThreadGroup(appStore.dispatcher, thread);

      const existsThread = this.threads.find((t) => t.id === thread.id);
      if (existsThread === undefined) {
        thread.counter = 0;
        thread.messages = [];
        thread.user = thread.user || {};
        thread.name = thread.user.name;
        thread.lastMessage = thread.lastMessage || "";

        this.threads.push({ ...thread });
      } else {
        existsThread.group = thread.group;
        existsThread.resolved = thread.resolved;
        existsThread.updatedDt = thread.updatedDt;
        existsThread.dispatcher = thread.dispatcher;
        existsThread.dispatcherId = thread.dispatcherId;
        existsThread.lastMessage = thread.lastMessage || "";
        existsThread.lastMessageDt = thread.lastMessageDt;
        existsThread.newMessagesCount = thread.newMessagesCount;
        if (thread.user) {
          existsThread.user = thread.user;
          existsThread.name = thread.user.name;
          existsThread.userId = thread.user.id;
        }
      }

      // sort threads when we explicitly denied it
      if (thread.sorting === undefined || thread.sorting) {
        this.sortThreads();
        this.recalcThreadsCount();
      }
    },
    updateThreadMessage(message: MessageDto) {
      const threadIndex = this.threads.findIndex((thread) => thread.id === message.threadId);

      if (threadIndex === -1) {
        // we can't add message if thread not exists
        return;
      }

      // find message to update or add new
      const existingMessage = this.threads[threadIndex].messages.find((m) => m.id === message.id);
      if (existingMessage === undefined) {
        this.threads[threadIndex].messages.push(message);
      } else {
        existingMessage.type = message.type;
        existingMessage.text = message.text;
        existingMessage.read = message.read;
      }
      this.threads[threadIndex].messages.sort(function (a, b) {
        if (a.created < b.created) return -1;
        if (a.created > b.created) return 1;
        return 0;
      });

      // rebuild thread in UI
      this.threads[threadIndex].counter++;
    },
    addOlderMessages(payload: { messages: MessageDto[]; threadId: number }) {
      const threadIndex = this.threads.findIndex((thread) => thread.id === payload.threadId);
      if (threadIndex === -1) return;
      const thread = this.threads[threadIndex];
      for (const message of payload.messages.reverse()) {
        if (thread.messages.findIndex((m) => m.id === message.id) === -1) {
          thread.messages.unshift(message);
        }
      }
    },
    saveDraftMessage(payload: { message: string; file: File | null; id: number }) {
      this.draftMessages[payload.id] = { message: payload.message, file: payload.file };
      localStorage.setItem(DRAFT_MESSAGES_STORAGE_KEY, JSON.stringify(this.draftMessages));
    },
    removeDraftMessage(id: number) {
      delete this.draftMessages[id];
      localStorage.setItem(DRAFT_MESSAGES_STORAGE_KEY, JSON.stringify(this.draftMessages));
    },
    saveTabsState(payload: string[]) {
      this.activeTabs = payload;
      localStorage.setItem(CHAT_TABS_STORAGE_KEY, JSON.stringify(this.activeTabs));
    },
    getThread(id: number) {
      const thread = this.threads.find((t) => t.id === id);
      return thread ? thread : null;
    },
    getDraftMessage(id: number): MessageDto | {} {
      let message = this.draftMessages[id];
      if (!message || typeof message !== "object") {
        message = {};
      }
      return message;
    },
    clearChat() {
      this.threads = [];
    },
  },
});
