<script>
import { useAppStore } from "@/store/app.store";
import { OrderFreightState } from "@/data/order";

export default {
  name: "StopChangesPopover",
  props: {
    stop: {
      type: Object,
      required: true,
    },
    freights: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: "Changes",
    },
  },
  data() {
    return {
      oldFacility: null,
      lastEdited: null,
    };
  },
  computed: {
    updatedFreights() {
      return this.freights.filter((f) => f.state === OrderFreightState.updated);
    },
  },
  methods: {
    onShow(val) {
      if (val) {
        if (this.stop.lastEditedBy) {
          const appStore = useAppStore();
          this.lastEdited = appStore.getDispatcher(this.stop.lastEditedBy);
        }
        this.setOldFacility();
      }
    },
    async setOldFacility() {
      if (this.oldFacility || !this.stop.oldFacilityId) {
        return;
      }
      if (this.stop.$oldFacility) {
        this.oldFacility = this.stop.$oldFacility;
        return;
      }
      const res = await this.$api.company.findFacilityById(this.stop.oldFacilityId);
      if (res.success) {
        this.oldFacility = res.data;
      }
    },
  },
};
</script>

<template>
  <v-menu location="top start" offset="4" width="483" @update:model-value="onShow">
    <template #activator="{ props }">
      <v-icon
        class="ml-2"
        color="warning"
        data-qa="alert-icon"
        icon="mdi-alert-circle-outline"
        size="20"
        v-bind="props"
      ></v-icon>
    </template>
    <v-card>
      <v-card-text>
        <div class="font-size-16 text-grey-darken-3 font-weight-500 mb-2">{{ label }}</div>
        <div v-if="updatedFreights.length > 0" class="bg-grey-lighten-4 pa-3 my-2">
          <div class="text-grey-darken-2 font-weight-500 mb-3">Freight changes</div>
          <v-row>
            <v-col cols="4">
              <div class="text-grey-darken-2">Previous freight:</div>
            </v-col>
            <v-col cols="8">
              <div v-for="freight in updatedFreights" :key="freight.id" class="text-grey-darken-2 font-weight-500">
                #{{ freight.number }}: {{ freight.oldQuantity || freight.quantity }} {{ freight.type }},
                {{ freight.oldWeight || freight.weight }} lbs
              </div>
            </v-col>
          </v-row>
        </div>
        <div v-if="oldFacility" class="bg-grey-lighten-4 pa-3 my-2">
          <v-row>
            <v-col cols="4">
              <div class="text-grey-darken-2">Previous facility:</div>
            </v-col>
            <v-col cols="8">
              <div class="mb-2">
                <router-link
                  :to="{ name: 'facilities-edit', params: { id: stop.facility?.id } }"
                  class="text-uppercase subheading font-weight-medium text-primary d-block text-decoration-none"
                  data-qa="facility-name"
                  target="_blank"
                >
                  {{ oldFacility?.name }}
                </router-link>
              </div>
              <div class="text-grey-darken-2 font-size-12" data-qa="popup-facility-address">
                {{ oldFacility?.addressLine }},
                {{ oldFacility?.address }}
              </div>
            </v-col>
          </v-row>
        </div>
        <v-row v-if="lastEdited" class="mt-2">
          <v-col cols="4">Last stop editor:</v-col>
          <v-col cols="8">
            <div class="font-weight-medium text-primary">{{ lastEdited.name }}</div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<style lang="scss" scoped></style>
