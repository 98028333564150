<script>
import { TravelOrderStatus, TravelOrderStatusMap } from "@/data/trip";

export default {
  name: "TravelOrderStatus",
  props: {
    travelOrder: { type: Object, required: true },
  },
  computed: {
    classes() {
      switch (this.travelOrder.status) {
        case TravelOrderStatus.notStarted:
          return "bg-indigo-lighten-2 text-white";
        case TravelOrderStatus.inProgress:
          return "bg-amber-lighten-2 text-grey-darken-3";
        case TravelOrderStatus.completed:
          return "bg-blue-lighten-2 text-white";
        case TravelOrderStatus.waitingForDryRun:
        case TravelOrderStatus.dryRun:
        case TravelOrderStatus.noDryRun:
        case TravelOrderStatus.cancelled:
          return "bg-red-lighten-2 text-white";
        default:
          return "bg-grey-lighten-4 text-grey-darken-3";
      }
    },
    label() {
      return TravelOrderStatusMap.find((s) => s.id === this.travelOrder.status)?.name || "Unknown";
    },
  },
};
</script>

<template>
  <div :class="classes" class="status-label rounded-lg pr-2 pl-2">
    {{ label }}
  </div>
</template>

<style scoped>
.status-label {
  white-space: nowrap;
  display: inline-block;
  line-height: 16px !important;
  font-size: 11px;
}
</style>
