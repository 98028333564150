<script>
import FileUploadingGrid from "@/components/FileUploadingGrid.vue";
import BaseView from "@/views/BaseView.vue";
import { OrderStopType } from "@/data/order";

export default {
  name: "OrderFiles",
  components: { FileUploadingGrid },
  mixins: [BaseView],
  props: {
    order: Object,
  },
  data() {
    return {
      orderFiles: [],
      tripFiles: [],
    };
  },
  computed: {
    cols() {
      return window.innerWidth >= 1920 ? 6 : 4;
    },
  },
  async created() {
    const orderFilesResp = await this.$api.orders.findOrderFiles(this.order.id);
    if (orderFilesResp.success) {
      this.orderFiles = orderFilesResp.data.map((f) => ({ ...f, previewPath: this.getPreviewPath(f) }));
    }

    if (this.order.tripId) {
      const tripResp = await this.$api.trips.getTripById(this.order.tripId);
      if (tripResp.success) {
        const stops = tripResp.data.routes[0].routeStops;
        const tripFilesResp = await this.$api.trips.getTripFiles(this.order.tripId);
        if (tripFilesResp.success) {
          let pickUpIndex = 1;
          let deliveryIndex = 1;
          for (const stop of stops) {
            let stopName = "";
            if (stop.type === OrderStopType.pickup) {
              stopName = "Pick up#" + pickUpIndex;
              pickUpIndex++;
            }
            if (stop.type === OrderStopType.delivery) {
              stopName = "Delivery#" + deliveryIndex;
              deliveryIndex++;
            }
            this.tripFiles.push({
              id: stop.id,
              name: stopName,
              files: tripFilesResp.data
                .filter((f) => f.tags.includes("route_stop:" + stop.id))
                .map((f) => ({
                  ...f,
                  previewPath: this.getPreviewPath(f),
                })),
            });
          }
        }
      }
    }
  },
  methods: {
    getFileType(file) {
      for (const tag of file.tags) {
        if (tag === "rateConfirmation") {
          return "Rate confirmation";
        } else if (tag === "other") {
          return "Other";
        } else if (tag === "tonu") {
          return "TONU";
        } else if (tag === "pod") {
          return "POD";
        } else if (tag.startsWith("bol")) {
          return "BOL";
        }
      }
      return "";
    },
  },
};
</script>

<template>
  <div>
    <div v-if="orderFiles?.length > 0">
      <div class="mt-6 mb-4 font-weight-500 font-size-20">Order files</div>
      <file-uploading-grid v-model="orderFiles" :columns="cols" disabled>
        <template #prefix="{ file }">
          <div class="mb-3 font-weight-500 font-size-16">{{ getFileType(file) }}</div>
        </template>
      </file-uploading-grid>
    </div>
  </div>
  <div v-for="stop in tripFiles.filter((s) => s.files.length > 0)" :key="'stop-' + stop.id">
    <div class="mt-6 mb-4 font-weight-500 font-size-20">{{ stop.name }} files</div>
    <file-uploading-grid v-model="stop.files" :columns="cols" disabled>
      <template #prefix="{ file }">
        <div class="mb-3 font-weight-500 font-size-16">{{ getFileType(file) }}</div>
      </template>
    </file-uploading-grid>
  </div>
</template>

<style lang="scss" scoped></style>
