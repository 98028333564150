<script>
import BaseView from "@/views/BaseView.vue";
import PhoneNumber from "@/components/PhoneNumber.vue";
import UsersMixin from "@/views/users/UsersMixin.vue";
import TruckStatus from "@/components/status/TruckStatus.vue";
import UserStatus from "@/components/status/UserStatus.vue";
import TruckEquipmentBlock from "@/views/trucks/blocks/EquipmentBlock.vue";
import TruckContactBlock from "@/views/trucks/blocks/ContactBlock.vue";
import TruckNotes from "@/views/trucks/blocks/TruckNotes.vue";
import ChangeTruckStatusAction from "@/views/trucks/actions/ChangeTruckStatusAction.vue";
import ReserveTruckAction from "@/views/trucks/actions/ReserveTruckAction.vue";
import TrucksMixin from "@/views/trucks/TrucksMixin.vue";
import GoogleMapsMixin from "@/mixins/google-maps.mixin";
import PopupMixin from "@/mixins/popup-mixin";
import OmniTable from "@/components/OmniTable.vue";
import ExpandButton from "@/components/controls/ExpandButton.vue";
import LanguageTag from "@/components/status/LanguageTag.vue";
import TruckLocationInfo from "@/views/trucks/components/TruckLocationInfo.vue";
import { DateTimeMixin } from "@/mixins/date-time.mixin";
import { mapState } from "pinia";
import { useAppStore } from "@/store/app.store";

export default {
  name: "TrucksList",
  components: {
    ReserveTruckAction,
    ChangeTruckStatusAction,
    LanguageTag,
    TruckLocationInfo,
    ExpandButton,
    OmniTable,
    UserStatus,
    TruckStatus,
    TruckNotes,
    TruckContactBlock,
    TruckEquipmentBlock,
    PhoneNumber,
  },
  mixins: [BaseView, DateTimeMixin, TrucksMixin, UsersMixin, PopupMixin, GoogleMapsMixin],
  props: {
    canEdit: {
      type: Boolean,
      default: false,
    },
    availableColumns: { type: Array, default: () => [] },
    searchParams: Object,
    compact: Boolean,
  },
  emits: ["params"],
  data() {
    return {
      data: { items: [], total: 0 },
      sortBy: [{ key: "distance", order: "asc" }],
      sortDesc: false,
      truck: {},
      expanded: [],
      loading: false,
      archived: false,
    };
  },
  computed: {
    ...mapState(useAppStore, ["dispatcher", "dispatchers"]),
    isTrucksSearch() {
      return this.availableColumns.indexOf("distance") !== -1;
    },
    headers() {
      const allHeaders = {
        name: { key: "name", title: this.$t("general.tt"), sortable: false, class: "col-name" },
        status: {
          key: "status",
          title: this.$t("general.status"),
          sortable: false,
          align: "center",
          class: "col-status",
        },
        driver: {
          key: "driver",
          title: this.$t("general.driver-info"),
          sortable: false,
        },
        size: {
          key: "size",
          title: this.$t("trucks.dims-payload"),
          sortable: false,
          align: "right",
          class: "col-dims",
        },
        available: {
          key: "available",
          title: this.$t("trucks.available-location"),
          sortable: false,
          class: "col-location",
        },
        current: {
          key: "current",
          title: this.$t("trucks.current-location"),
          sortable: false,
          class: "col-location",
        },
        contact: {
          key: "contact",
          title: this.$t("trucks.contact-person"),
          sortable: false,
        },
        distance: {
          key: "distance",
          title: this.$t("trucks.distance"),
          sortable: false,
          class: "col-distance",
        },
        notes: {
          key: "notes",
          title: this.$t("trucks.notes"),
          sortable: false,
          class: "notes",
        },
      };

      const headers = this.availableColumns.map((name) => allHeaders[name]);
      if (this.canEdit) {
        headers.push({
          title: "Actions",
          key: "actions",
          align: "left",
          class: "col-actions",
          sortable: false,
        });
      }

      return headers;
    },
  },
  watch: {
    async searchParams(newParams, oldParams) {
      if (JSON.stringify(newParams) !== JSON.stringify(oldParams)) {
        this.params.page = 1;
        await this.refresh();
      }
    },
    archived() {
      this.refresh();
    },
  },
  async created() {
    if (this.searchParams) {
      this.params.page = 1;
      await this.refresh();
    }
  },
  methods: {
    async expand(itemId) {
      if (this.expanded.length === 1 && this.expanded[0] === itemId) {
        this.expanded = [];
      } else {
        this.expanded = [itemId];

        const truck = this.data.items.find((t) => t.id === itemId);
        await this.loadExtraData(truck);
      }
    },
    async loadExtraData(truck) {
      // load truck owner coordinators
      if (truck.coordinators === undefined) {
        const resp = await this.$api.users.findOwnerCoordinators(truck.ownerId);
        if (resp.success) {
          truck.coordinators = resp.data;
        }
      }

      // load driver data for truck owner only once
      if (truck.owner && truck.owner.isDriver && truck.owner.licenseNumber === undefined) {
        const resp = await this.$api.users.findDriverById(truck.owner.id);
        if (resp.success) {
          truck.owner = { ...truck.owner, ...resp.data };
        }
      }

      // load driver data for truck driver 1 only once
      if (truck.driverId && truck.driver.licenseNumber === undefined) {
        const resp = await this.$api.users.findDriverById(truck.driverId);
        if (resp.success) {
          truck.driver = { ...truck.driver, ...resp.data };
        }
      }

      // load driver data for truck driver 2 only once
      if (truck.driver2Id && truck.driver2.licenseNumber === undefined) {
        const resp = await this.$api.users.findDriverById(truck.driver2Id);
        if (resp.success) {
          truck.driver2 = { ...truck.driver2, ...resp.data };
        }
      }
    },
    getItemClass(item) {
      if (
        item.status === "h" ||
        item.owner.ownerStatus === "h" ||
        (item.driver && (item.driver.driverStatus === "h" || item.driver.driverStatus === "s"))
      )
        return "status-hold";
      return "";
    },
    sortTrucks() {
      const sortingField = this.searchParams.searchByHomeLocation ? "deliveryDistance" : "pickupDistance";

      this.data.items.sort((a, b) => {
        if (a[sortingField] < b[sortingField]) return -1;
        if (a[sortingField] > b[sortingField]) return 1;
        return 0;
      });
    },
    async refresh() {
      this.loading = true;
      const params = { ...this.searchParams, ...this.params };
      if (this.isTrucksSearch) {
        params.pageSize = 100;
      }
      params.archived = this.archived;

      const resp = await this.$api.trucks.findTrucks(params);
      if (resp.success) {
        this.data = resp.data;
        this.$emit("params", resp.data.params);
        this.sortTrucks();
      }

      this.loading = false;
    },
    updateTruck(truck) {
      const currentTruck = this.data.items.find((t) => t.id === truck.id);
      if (currentTruck) {
        currentTruck.note = truck.note;
        currentTruck.reservedTill = truck.reservedTill;
        currentTruck.reservedById = truck.reservedById;
        if (currentTruck.driver && truck.driver) {
          currentTruck.driver.note = truck.driver.note;
          currentTruck.driver.driverNoteText = truck.driver.driverNoteText;
        }
        if (currentTruck.driver2 && truck.driver2) {
          currentTruck.driver2.note = truck.driver2.note;
          currentTruck.driver2.driverNoteText = truck.driver2.driverNoteText;
        }
      }
    },
    async openMapSettingsWindow() {
      if ("getScreenDetails" in window && "isExtended" in screen && screen.isExtended) {
        await window.getScreenDetails();
      }
      const routeData = this.$router.resolve({ name: "map-position" });
      this.openPopup({
        url: routeData.href,
        title: "PopupSettings",
        w: this.popupWidth(),
        h: this.popupHeight(),
        x: this.popupX(),
        y: this.popupY(),
      });
    },
    openRouteMap(from, to) {
      const href = this.getRouteUrl(from, to);
      this.openPopup({
        url: href,
        title: "RouteMap",
        w: this.popupWidth(),
        h: this.popupHeight(),
        x: this.popupX(),
        y: this.popupY(),
      });
    },
    openPlaceMap(place) {
      const href = this.getPlaceUrl(place, 6);
      this.openPopup({
        url: href,
        title: "PlaceMap",
        w: this.popupWidth(),
        h: this.popupHeight(),
        x: this.popupX(),
        y: this.popupY(),
      });
    },
  },
};
</script>

<template>
  <div v-if="data.pickupDeliveryDistance" class="text-body-2 mb-2">
    {{ data.pickupDeliveryDistance }}<br />
    {{ data.pickupAddress }}
    <span v-if="data.pickupDeliveryDistance > 0">
      &rarr; {{ data.deliveryAddress }} &bsim; {{ $filters.miles(data.pickupDeliveryDistance) }} miles
      <a
        v-if="data.pickupLocation && data.deliveryLocation"
        class="text-decoration-none text-primary ml-2"
        target="_blank"
        @click="openRouteMap(data.pickupLocation, data.deliveryLocation)"
      >
        <v-icon color="blue" size="small">mdi-open-in-new</v-icon> VIEW ROUTE
      </a>
    </span>
    <a
      v-if="data.pickupDeliveryDistance === 0"
      class="text-decoration-none text-primary ml-2"
      target="_blank"
      @click="openPlaceMap(data.pickupLocation)"
    >
      <v-icon color="blue" size="small">mdi-open-in-new</v-icon>
      VIEW ON MAP
    </a>
  </div>
  <omni-table
    v-model:archived="archived"
    v-model:expanded="expanded"
    v-model:sort-by="sortBy"
    :compact="compact"
    :footer-props="{ 'items-per-page-options': isTrucksSearch ? [] : pagination.itemsPerPage }"
    :headers="headers"
    :hide-default-footer="isTrucksSearch"
    :items="data.items"
    :items-length="data.total"
    :items-per-page="isTrucksSearch ? data.total : params.pageSize"
    :loading="loading"
    :mobile-breakpoint="0"
    class="trucks-table"
    show-archive
    @update:page="updatePage"
    @update:items-per-page="updatePageSize"
  >
    <template #[`column.distance`]="{ column }">
      <div class="d-flex justify-space-between align-center">
        <div>
          {{ column.title }}
        </div>
        <div class="cursor-pointer" @click="openMapSettingsWindow">
          <v-icon color="primary">mdi-cog</v-icon>
        </div>
      </div>
    </template>
    <template #[`item.name`]="{ item }">
      <div :class="getItemClass(item)" class="d-flex align-center">
        <expand-button
          :expanded="expanded.length === 1 && expanded[0] === item.id"
          class="mr-3"
          color="primary"
          size="xsmall"
          @click="expand(item.id)"
        />
        <div>
          <router-link
            :to="{ name: 'truck-view', params: { id: item.id } }"
            class="text-decoration-none text-primary mb-1"
            data-qa="truck-number"
          >
            {{ item.number }}
          </router-link>
          <div class="text-grey-darken-2 font-size-12" data-qa="truck-type">{{ getTruckType(item.type) }}</div>
        </div>
      </div>
    </template>
    <template #[`item.status`]="{ item }">
      <div class="col-status" data-qa="truck-status">
        <truck-notes
          v-if="
            item.note ||
            (item.driver && (item.driver.driverNoteText || item.driver.note)) ||
            (item.driver2 && (item.driver2.driverNoteText || item.driver2.note))
          "
          :truck="item"
        />
        <change-truck-status-action :truck="item" @success="refresh">
          <truck-status :truck="item" class="pointer" />
        </change-truck-status-action>
      </div>
    </template>
    <template #[`item.driver`]="{ item }">
      <div v-if="item.driver">
        <div class="d-flex align-center mb-2">
          <v-tooltip location="top">
            <template #activator="{ props }">
              <v-btn
                class="font-weight-600 status-item text-uppercase text-indigo-lighten-2 pa-0 font-weight-regular mr-2"
                color="bg-grey-lighten-4"
                data-qa="truck-list-driver-role"
                v-bind="props"
                variant="tonal"
              >
                {{ $t("users.dr") }}
              </v-btn>
            </template>
            <span class="text-body-2">{{ $t("users.driver") }}</span>
          </v-tooltip>
          <span v-if="item.driver.id === item.driver.contactPersonId" class="currency-icon mr-2 small" />
          <router-link
            :to="{ name: 'user-view', params: { id: item.driver.id } }"
            class="text-primary text-decoration-none mr-2 line-height-1"
            data-qa="truck-driver-name"
          >
            {{ item.driver.name }}
          </router-link>
          <user-status :status="item.driver.driverStatus" />
        </div>

        <div class="d-flex align-center">
          <phone-number
            :number="item.driver.phone"
            class="font-size-8 text-decoration-none text-primary"
            data-qa="truck-driver-phone"
          />
          <language-tag :language="item.driver.language" class="ml-2"></language-tag>
        </div>
      </div>
    </template>
    <template #[`item.distance`]="{ item }">
      <div v-if="item.pickupDistance" class="d-flex text-no-wrap align-center distance-block">
        <v-icon :color="!item.deliveryDistance ? 'text-grey' : 'text-black'" class="mr-1" size="small">
          mdi-cube-outline
        </v-icon>
        <span :class="{ 'font-weight-bold': item.deliveryDistance }" class="font-size-12 text-grey-darken-3 mr-2">
          {{ $filters.miles(item.pickupDistance) }} miles
        </span>
        <a
          v-if="item.availableLocation && item.pickupLocation"
          :class="item.id"
          class="text-decoration-none text-primary font-size-11 cursor-pointer"
          target="_blank"
          @click="openRouteMap(item.availableLocation, item.pickupLocation)"
        >
          View route
        </a>
      </div>
      <div v-if="item.deliveryDistance" class="d-flex btext-no-wrap align-center distance-block">
        <v-icon class="mr-1" color="text-grey-darken-2" size="small">mdi-home-outline</v-icon>
        <span class="font-size-12 text-grey-darken-3 mr-2">{{ $filters.miles(item.deliveryDistance) }} miles</span>
        <a
          v-if="item.deliveryLocation && item.driver && item.driver.addressLocation"
          :class="item.id"
          class="text-decoration-none text-primary font-size-11 cursor-pointer"
          target="_blank"
          @click="openRouteMap(item.deliveryLocation, item.driver.addressLocation)"
        >
          View route
        </a>
      </div>
    </template>
    <template #[`item.contact`]="{ item }">
      <div v-if="item.driver && item.driver.contactPerson">
        <div class="d-flex align-center mb-2">
          <v-tooltip top>
            <template #activator="{ props }">
              <v-btn
                class="status-item text-uppercase text-indigo-lighten-2 pa-0 mr-2 font-weight-600"
                color="bg-grey-lighten-4"
                v-bind="props"
                variant="tonal"
              >
                {{ $t("users.dr") }}
              </v-btn>
            </template>
            <span class="text-body-2">{{ $t("users.driver") }}</span>
          </v-tooltip>
          <span v-if="item.driver.id === item.driver.contactPersonId" class="currency-icon mr-2 small" />
          <router-link
            :to="{ name: 'user-view', params: { id: item.driver.contactPersonId } }"
            class="text-primary text-decoration-none mr-2 line-height-1"
          >
            {{ item.driver.contactPersonName }}
          </router-link>
          <user-status :status="item.driver.driverStatus" />
        </div>

        <div class="d-flex align-center">
          <phone-number :number="item.driver.phone" class="font-size-12 text-decoration-none text-primary" />
          <span
            class="ml-2 text-uppercase bg-grey-lighten-4 rounded text-grey-darken-1 pr-1 pl-1 caption type-language"
          >
            {{ $filters.upper(item.driver.language) }}
          </span>
        </div>
      </div>
    </template>
    <template #[`item.notes`]="{ item }">
      <truck-notes :truck="item" @update="updateTruck" />
      <div v-if="item.driver" class="text-grey-darken-3">
        {{ $filters.mdash(item.driver.driverNote) }}
      </div>
      <div v-if="item.note" class="text-grey-darken-3">
        {{ item.note }}
      </div>
    </template>
    <template #[`item.size`]="{ item }">
      <template v-if="item.trailer">
        <div class="text-grey-darken-3" data-qa="truck-trailer-dims">
          {{ item.trailer.length }}&#8243;&nbsp;х&nbsp;{{ item.trailer.minWidth }}&#8243;&nbsp;x&nbsp;{{
            item.trailer.minHeight
          }}&#8243;
        </div>
        <div class="text-grey-darken-1 font-size-12">{{ item.trailer.payload }} lbs</div>
      </template>
      <template v-else> &mdash;</template>
    </template>
    <template #[`item.available`]="{ item }">
      <truck-location-info
        :location="item.availableLocation"
        :show-tooltip="false"
        :time="item.availableDate"
        show-changed-by
      />
    </template>
    <template #[`item.current`]="{ item }">
      <truck-location-info :location="item.currentLocation" :time="item.currentLocationTime" show-indicator />
    </template>
    <template #[`item.actions`]="{ item }">
      <div class="d-flex align-center">
        <v-btn
          :to="{ name: 'truck-view', params: { id: item.id } }"
          class="pa-1 table-action"
          size="small"
          variant="flat"
        >
          <v-icon class="text-primary">mdi-eye-outline</v-icon>
        </v-btn>

        <v-tooltip v-if="item.reservedById && getReservationTime(item) > -1" top>
          <template #activator="{ props }">
            <span v-bind="props">
              <reserve-truck-action :truck="item" @success="refresh">
                <v-btn
                  :disabled="item.reservedById !== dispatcher.id"
                  class="font-weight-600"
                  color="success"
                  size="small"
                  variant="flat"
                >
                  RESERVED, {{ getReservationTime(item) }}m
                </v-btn>
              </reserve-truck-action>
            </span>
          </template>
          <span>
            Reserved by {{ getReservedBy(item) }} until
            {{ dateTZ(item.reservedTill, dispatcher.timezone) }}
          </span>
        </v-tooltip>

        <reserve-truck-action v-else :truck="item" @success="refresh">
          <v-btn class="text-uppercase font-weight-600" color="primary" size="small" variant="flat"> Reserve</v-btn>
        </reserve-truck-action>
      </div>
    </template>
    <template #expanded-row="{ columns, item }">
      <tr>
        <td :colspan="columns.length" class="dop-row">
          <div class="bg-grey-lighten-4 pt-3 pr-4 pb-3 pl-4 dop-row__inner">
            <div class="blocks-items d-flex flex-wrap">
              <truck-equipment-block :truck="item" />
              <truck-contact-block
                v-if="item.owner && ![item.driverId, item.driver2Id].includes(item.ownerId)"
                :contact-person="
                  item.driver && item.driver.contactPersonId && item.ownerId === item.driver.contactPersonId
                "
                :label="$t('drivers.owner')"
                :user="item.owner"
                owner
                show-certificates
              />
              <truck-contact-block
                v-if="item.driver"
                :contact-person="item.driver.contactPersonId && item.driverId === item.driver.contactPersonId"
                :label="item.driver.id === item.owner.id ? $t('drivers.owner-driver') : $t('drivers.driver')"
                :user="item.driver"
                show-certificates
              />
              <truck-contact-block
                v-if="item.driver2"
                :contact-person="item.driver2Id === item.driver.contactPersonId"
                :label="item.driver2.id === item.owner.id ? $t('drivers.owner-driver') : $t('drivers.second-driver')"
                :user="item.driver2"
                show-certificates
              />
              <truck-contact-block
                v-if="
                  item.driver &&
                  item.driver.contactPersonId &&
                  ![item.driverId, item.driver2Id, item.ownerId].includes(item.driver.contactPersonId)
                "
                :contact-person="true"
                :label="$t('drivers.contact-person')"
                :user="item.driver.contactPerson"
              />
              <truck-contact-block
                v-for="coordinator in item.coordinators"
                :key="coordinator.userId"
                :full-access="coordinator.fullAccess"
                :show-status="false"
                :user="coordinator.user"
                label="Coordinator"
              />
            </div>
          </div>
        </td>
      </tr>
    </template>
  </omni-table>
</template>

<style lang="scss" scoped>
.dop-row {
  border-right: 0 !important;
  padding: 0 0 12px 0 !important;
  background: #f8f8fb;

  &__inner {
    box-shadow: 0 4px 4px -1px rgba(0, 0, 0, 0.16);
  }
}

.blocks-items {
  margin: 0 -8px;
}

.table-action {
  width: auto !important;
  min-width: auto !important;
  padding: 0 2px !important;
  background: transparent !important;

  &:not(:last-child) {
    margin-right: 10px;
  }
}

.location {
  line-height: 17px;
}

.table-status {
  white-space: nowrap;
  display: inline-block;
  line-height: 16px !important;
}

.type-language {
  line-height: 12px;
  font-size: 11px;
  padding: 2px 0;
}

.line-height-1 {
  line-height: 1 !important;
}

.status-item {
  font-size: 9px;
  line-height: 16px;
  height: 16px !important;
  border-radius: 100px;
  padding: 0 2px !important;
  min-width: auto !important;
}

.currency-icon {
  width: 18px;
  flex: 0 0 18px;
  height: 18px;
  font-size: 13px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  background: url(@/assets/images/currency-icon.svg) no-repeat;
  background-size: 18px;

  &.small {
    width: 14px;
    flex: 0 0 14px;
    height: 14px;
    background-size: 14px;
  }
}
</style>

<style lang="scss">
td:has(.status-hold) {
  border-left: 3px solid #ff5252 !important;
}

.col-name {
  min-width: 160px;
  width: 10%;
}

thead .col-status {
  width: 6%;
  min-width: 145px;
}

.col-info {
  width: 15%;
  min-width: 250px;
}

.col-dims,
.col-payload {
  width: 1%;
  min-width: 150px;
}

.col-location {
  width: 15%;
  min-width: 165px;
}

.col-actions {
  width: 1%;
  min-width: 170px;
}

.col-distance {
  width: 11.8%;
  min-width: 180px;
}

.v-application .location-block .caption {
  font-size: 11px !important;
  line-height: 12px;
}

.font-size-11 {
  font-size: 11px;
}
</style>
