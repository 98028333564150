<script lang="ts" setup>
import { computed, defineProps } from "vue";

const props = withDefaults(
  defineProps<{
    bgColor?: string;
  }>(),
  {
    bgColor: "amber-lighten-5",
  },
);

const backgroundClass = computed(() => `bg-${props.bgColor}`);
</script>

<template>
  <div
    :class="backgroundClass"
    class="rounded text-grey-darken-3 border-warning pa-2 d-flex align-center font-size-12 font-weight-500"
    data-qa="update-alert-message"
  >
    <v-icon color="warning" icon="mdi-information-outline" size="20"></v-icon>
    <div class="ml-1">
      <slot></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
