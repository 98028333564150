<script>
import { OrderFreightType } from "@/data/order";

export default {
  name: "OrderStopFreightDetails",
  props: {
    freights: Array,
  },
  computed: {
    quantityPieces() {
      return this.freights.reduce(
        (sum, f) => sum + (f.type === OrderFreightType.pieces && !f.isDeleted ? f.quantity : 0),
        0,
      );
    },
    quantityPallets() {
      return this.freights.reduce(
        (sum, f) => sum + (f.type === OrderFreightType.pallets && !f.isDeleted ? f.quantity : 0),
        0,
      );
    },
    weight() {
      return this.freights.reduce((sum, f) => sum + (!f.isDeleted ? f.weight : 0), 0);
    },
  },
};
</script>

<template>
  <div class="d-flex align-center mt-6 text-grey-darken-2 font-weight-500">
    <svg height="20" width="20">
      <use xlink:href="@/assets/images/pallet.svg#pallet"></use>
    </svg>
    <div class="ml-2">
      <div v-if="quantityPallets">{{ quantityPallets }} pallets</div>
      <div v-if="quantityPieces">{{ quantityPieces }} pieces</div>
    </div>
    <svg class="ml-4" height="20" width="20">
      <use xlink:href="@/assets/images/weight.svg#weight"></use>
    </svg>
    <div class="ml-2">{{ weight }} lbs</div>
  </div>
</template>

<style lang="scss" scoped></style>
