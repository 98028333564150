<script lang="ts">
export default {
  name: "UpdateIndicator",
};
</script>

<template>
  <span class="indicator"></span>
</template>

<style lang="scss" scoped>
@import "@/assets/style/color";

.indicator {
  display: inline-block;
  height: 8px;
  width: 8px;
  background-color: $orange;
  border-radius: 50%;
}
</style>
