<script lang="ts" setup>
import { computed, defineProps } from "vue";
import type { OrderFreightDto } from "@/api/orders/dto/order.dto";
import { OrderFreightState } from "@/data/order";

const props = defineProps<{
  freight: OrderFreightDto;
}>();

const stateClass = computed(() => {
  if (props.freight.isDeleted) return "bg-grey-darken-2 text-white";
  if (props.freight.state === OrderFreightState.updated) return "bg-warning text-white";
  if (props.freight.state === OrderFreightState.new) return "bg-success text-white";
  return null;
});

const stateLabel = computed(() => {
  if (props.freight.isDeleted) return "Deleted";
  if (props.freight.state === OrderFreightState.updated) return "Updated";
  if (props.freight.state === OrderFreightState.new) return "New";
  return null;
});
</script>

<template>
  <div
    v-if="stateLabel"
    :class="stateClass"
    class="rounded py-1 px-2 font-size-11 font-weight-600 text-uppercase line-height-1"
  >
    {{ stateLabel }}
  </div>
</template>

<style lang="scss" scoped></style>
